// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import Gmaps from '@/blocks/utils/b-google-maps/index';
import GMarker from '@/blocks/utils/b-google-maps/markers';
import '@/blocks/utils/b-google-maps/markerclusterer';

import hotelView from './b-hotels-hotel/index';
import hotelModel from './b-hotels-hotel/model';

import HotelsPopupOffers from '@/blocks/pages/b-hotels/b-hotels-popup-offers/b-hotels-popup-offers';
import HotelsModalOffersView from '@/blocks/pages/b-hotels/b-hotels-popup-offers/index';
import SearchProgressBarView from '@/blocks/pages/b-hotels/b-hotels-search-bar/index';
import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .hotels-map-close': 'mapClose',
		'click .hotels-map-open': 'mapOpen',
		'click .hotels-map-fix': 'fixMapTop',
		'click .hotels-map-expand': 'mapExpand',
		'click .b-hotels__search-all': 'showAllHotels',
		'click .b-hotels__search-close': 'closeResultPopup',
		'click .b-hotels__search-filters-reset': 'resetFilters',
	},

	ui: {
		mapContainer: '.b-hotels__map',
		hotelsResultContainer: '.b-hotels__results',
		hotelsResultMapContainer: '.b-hotels__results--map',
		searchCountContainer: '.b-hotels__search',
		noResultsContainer: '.b-hotels__search-no-results',
		searchProgressBarContainer: '.b-hotels__search-progress-bar',
	},

	views: [],
	models: [],
	markers: [],
	mergeHotels: [],

	applyFilters: false,

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			name: 'scroll',
			callback: this.searchResultScroll.bind(this),
		});
	},

	initialize() {
		const results = this.options.results;

		this.approvalAllowed = this.options.results.approvalAllowed === undefined ? true : (this.options.results.approvalAllowed === true);
		this.issueAllowed = (this.options.results.issueAllowed === true);
		this.approvalOffers = [];

		this.searchParams = STATE.getSearchParametrs();
		this.roomsCount = _.size(this.searchParams.rooms) || this.searchParams.roomsCount || 1;
		this.manyRooms = this.roomsCount > 1;
		this.parent = this.options.parent;
		const siteSettings = STATE.getSettings();
		this.isMapApiAvailable = siteSettings.generalSettings && !_.isEmpty(siteSettings.generalSettings.googleMapsApiKey);

		this.addEventListeners = this.addEventListeners.bind(this);
		this.onFiltersCollectionChanged = this.onFiltersCollectionChanged.bind(this);
		this.onBeforeFilterCollection = this.onBeforeFilterCollection.bind(this);

		this.listenTo(this, 'applyEventListeners', this.addEventListeners);

		try {
			STATE.showLoader();
			this.render();
		} catch (e) {
			if (window.ErrorHandler != null) {
				const error = window.ErrorHandler.getLineException(e.stack);
				window.ErrorHandler.callbackErrorHandler(_.extend({}, error, {
					message: e.message,
					error: {
						stack: e.stack,
						isSilentError: true,
					},
				}));
			}

			return;
		}

		_.defer(() => {
			this.resetResults();
			this.$('.b-hotels__map-wrapper').addClass('dn');
			if (this.isMapApiAvailable) {
				this.initializeMap();
				this.mapUnexpand();
				this.$('.b-hotels__map-wrapper').removeClass('dn');
			}
			this.resetControls();

			STATE.hideLoader();
			this.initializeHotels(results, true);
		});
	},

	setPreferences(preferences) {
		if (this.parent != null) {
			this.parent.configuration = preferences;
			this.parent.issueAllowed = (preferences.issueAllowed === true);
			this.parent.hasTravelPolicy = (preferences.hasTravelPolicy === true);

			this.hasTravelPolicy = this.parent.hasTravelPolicy;
			this.issueAllowed = this.parent.issueAllowed;
		}
	},

	getActiveSorting() {
		return this.options.parent.sortingView.active;
	},

	initializeSearchProgressBar(isFirstCall) {
		if (isFirstCall && this.progressBarView == null) {
			this.progressBarView = new SearchProgressBarView({
				parent: this,
				model: this.model,
			});
			this.ui.searchProgressBarContainer.html(this.progressBarView.$el);
			this.ui.searchProgressBarContainer.show();
			this.progressBarView.move();
		}
	},

	showMessagePopup(result) {
		if (result != null && result.data != null && result.data.messages != null && result.data.messages.length > 0) {
			const messages = result.data.messages.reduce((prev, curr) => {
				let resultMessages = prev;
				resultMessages += `${curr.text}<br/>`;
				return resultMessages;
			}, '');

			const isWarning = _.some(result.data.messages, (m) => m.severity === 'WARNING');
			const popup = new Widgets.Popup({
				content: isWarning ? [L10N.get('hotels.noOffers'), messages].join('<br/><br/>') : messages,
				closeOnlyOnAction: false,
				type: isWarning ? 'danger' : 'info',
				actions: [
					{
						label: L10N.get('searchForm.close'),
						action: () => {
							popup.hide();
						},
					},
					{
						label: L10N.get('errors.noTicketsButton'),
						action: () => {
							popup.hide();
							STATE.navigate(STATE.ROUTES.INDEX);
						},
					},
				],
				classes: 'b-reload-popup',
			});

			this.showWarningMessage = isWarning;
			popup.show();
		}
	},

	initializeHotels(results = {}, isFirstCall = true) {
		const { restHotelToken, searchFinished = false, hotelCards = [], originCityInfo, showGdsAccountName } = results;
		this.showGdsAccountName = showGdsAccountName || false;
		this.originCityInfo = originCityInfo;
		this.initializeSearchProgressBar(isFirstCall);

		if (!_.isEmpty(hotelCards)) {
			let hotels = [];
			hotels = this.getMergeHotels(hotelCards);
			let newViewsToSort = [];
			this.mergeHotels = Array.prototype.concat(this.mergeHotels, hotels);
			_.each(hotels, (hotel, index) => {
				const model = new hotelModel({
					...hotel,
					originCityInfo: this.originCityInfo,
					hasTravelPolicy: this.hasTravelPolicy,
				});
				const view = new hotelView({index, hotel, model, parent: this});

				this.models.push(model);
				newViewsToSort.push(view);
			});
			if (this.parent && this.parent.sortingView) newViewsToSort = this.parent.sortingView.sortGiven(newViewsToSort);
			this.views.push(...newViewsToSort);
			this.renderAndApplyFilters(isFirstCall);

			this.addMarkersHotels(hotels);
		}

		// Continue search hotels
		if (searchFinished === false) {
			this.searchParams = STATE.getSearchParametrs(); // IBECORP-3225, IBECORP-3224 Without this code, model didn't update on second step
			const params = _.extend({}, this.searchParams, {restHotelToken});
			if (_.find(params.serviceTypes, (p) => p.uid === 'HOTEL')) {
				axios.post('/midoffice/ibecorp-b2b/hotels/searchOffers', params).then((response) => {
					const preferences = _.pick(STATE.getHotelSearchResults(), ['issueAllowed', 'approvalAllowed', 'roomType', 'searchResultSortType']);
					const data = response.data.result;

					this.showMessagePopup(response);
					this.parent.hasTravelPolicy = this.parent.hasTravelPolicy || data.hasTravelPolicy;

					this.initializeHotels(data, isFirstCall && _.isEmpty(hotelCards));
					this.initializeHotelCounter();

					// Limit save to store
					const hotels = Array.prototype.concat(this.mergeHotels, this.notRenderedHotels);
					if (_.size(hotels) < 500) {
						STATE.setHotelsSearchResult(_.extend({}, data, preferences, {
							hotelCards: hotels.map((h) => {
								delete h.marker;
								delete h.parent;

								return h;
							}),
						}));
					}
				}).catch((err) => {
					STATE.hideLoader();
					if (axios.isCancel(err)) {
						logger.error(err);
					} else {
						throw new Error(err);
					}
				});
			}
		} else if (searchFinished === true && _.isEmpty(this.mergeHotels)) {
			STATE.hideLoader();

			if (!this.showWarningMessage) {
				this.noResults();
			}
		}

		if (searchFinished === true) {
			this.parent.silentRender = false;
			if (this.progressBarView != null) {
				this.progressBarView.percentage = 100;

				this.parent.filtersView.updateSelectedFiltersAfterSearch();

				setTimeout(() => {
					this.ui.searchProgressBarContainer.empty();
					this.progressBarView = this.progressBarView && this.progressBarView.destroyView();
					this.initializeHotelCounter();
				}, 1000);
			}
		}

		// When find first part hotels
		if (!_.isEmpty(hotelCards) && this.searchFinished !== true) {
			$('.b-tickets-container-hotels').addClass('search-is-complete');
			this.mapSetDefaultCenter();
			this.searchFinished = true;
		}
	},

	initializeHotelCounter() {
		const count = _.filter(this.models, (item) => item._filtered !== true).length;
		const $container = this.$el.find('.b-hotels__result-counter');

		$container.empty();
		if (count > 0) $container.html(`${L10N.get('hotels.hotelResultsAmount')}: ${count}`);
	},

	renderAndApplyFilters(isFirstCall) {
		this.parent.silentRender = isFirstCall === false;
		if (isFirstCall) {
			this.parent.renderFilters();
			this.parent.filtersView.triggerChangeEvent();
			return;
		}
		if (this.parent.filtersApplied) {
			this.queueRender = this.parent.filtersView.updateFilters(this.views);
		} else {
			this.parent.filtersView.updateFilters();
			this.queueRender = this.views;
		}
		if (this.queueRender.length < 20 || (this.queueRender.length >= 20 && this.$('.b-hotel__visible').length < 10 && !this.parent.filtersApplied)) {
			setTimeout(() => this.silentRenderResults(), 0);
		}
	},

	initializeMap() {
		const gmaps = window.google;

		if (this.map != null) {
			return;
		}

		if (_.isEmpty(gmaps)) {
			throw Error('map is not loaded');
		}

		this.gmapsIsLoaded = true;

		const options = {
			center: {
				latitude: -34.397,
				longitude: 150.644,
			},
			zoom: 8,
			disableDefaultUI: true,
			clickableIcons: false,
			styles: [{
				featureType: 'poi.business',
				elementType: 'labels',
				stylers: [
					{visibility: 'off'},
				],
			}],
		};

		const map = new gmaps.maps.Map(this.ui.mapContainer.get(0), options);
		this.map = map;
		if (gmaps && gmaps.maps && gmaps.maps.mapTypeId) this.map.setMapTypeId(gmaps.maps.mapTypeId.ROADMAP);

		this.hotelMarkersOverlay = GMarker.getHotelsMarker(this);
		Gmaps.setMapControls(map);

		if (STATE.checkViewport('(max-width: 768px)')) this.$('.b-hotels__map-wrapper').addClass('dn');
	},

	mapSetDefaultCenter() {
		if (this.map == null) {
			return;
		}

		const initialHotel = _.find(this.mergeHotels, (h) => h.address && h.address.cityInfo);
		if (initialHotel != null) {
			this.initialLocation = _.pick(initialHotel.address.cityInfo, ['latitude', 'longitude']);
		} else {
			this.initialLocation = {
				latitude: -34.397,
				longitude: 150.644,
			};
		}

		const latLng = new window.google.maps.LatLng(
			this.initialLocation.latitude,
			this.initialLocation.longitude,
		);
		this.map.panTo(latLng);
	},

	initCluster() {
		if (!this.isMapApiAvailable) return;
		if (this.markerCluster != null) {
			this.clearCluster();
			return;
		}

		const initCluster = (div) => {
			$(div).tooltip({
				title: L10N.get('hotels.map.showHotels'),
				placement: 'bottom',
				container: 'body',
			}).on('click', function onClick() {
				$(this).tooltip('dispose');
			});
			$(div).css('lineHeight', `${($(div).height() - 2)}px`);
			return div;
		};

		const clusterStyles = [
			{
				textColor: 'white',
				textFamily: '"PT Sans", Helvetica, Arial, sans-serif',
				textSize: '14',
				className: 'hotels-cluster sln-hotels-cluster',
				preInit: initCluster,
				height: 29,
				width: 29,
			},
			{
				textColor: 'white',
				textFamily: '"PT Sans", Helvetica, Arial, sans-serif',
				textSize: '14',
				className: 'hotels-cluster sln-hotels-cluster',
				preInit: initCluster,
				height: 41,
				width: 41,
			},
		];

		this.mcOptions = {
			gridSize: 50,
			styles: clusterStyles,
			maxZoom: 18,
		};

		if (window.MarkerClusterer) {
			this.markerCluster = new window.MarkerClusterer(this.map, this.markers, this.mcOptions);
		}
	},

	clearCluster() {
		this.markers = [];
		if (this.markerCluster != null && this.markerCluster instanceof window.MarkerClusterer) {
			this.markerCluster.clearMarkers();
		}
	},

	onFiltersCollectionChanged() {
		const {filtersView = {}} = this.parent;
		const {collection} = filtersView;

		if (collection) {
			const values = this.getFiltersValue(collection.models);
			this.ui.hotelsResultMapContainer.html('');
			this.applyFilters = !_.isEmpty(values);

			this.clearCluster();
			if (collection != null && this.applyFilters) {
				this.addMarkersHotels(_.map(collection.visibleResults, (v) => v.toJSON()), true);
			} else {
				this.addMarkersHotels([
					..._.map(this.views, (v) => v.hotel),
					...this.notRenderedHotels,
				], true);
			}

			if (collection != null && collection.visibleResults.length === 0) {
				this.ui.noResultsContainer.removeClass('dn');
			} else {
				this.ui.noResultsContainer.addClass('dn');
			}
		}
	},

	_onBeforeFilterCollectionFields: [
		'price',
		'travelPolicyCompliances',
		'availability',
		'mealIncluded',
		'services',
		'providers',
		'penalties',
		'tripartiteContract',
	],

	onBeforeFilterCollection() {
		const {filtersView = {}} = this.parent;
		const {collection} = filtersView;

		if (collection) {
			const values = this.getFiltersValue(collection.models, this._onBeforeFilterCollectionFields);
	
			_.each(collection.results, (model) => {
				model.trigger('beforeFilterOffers', values);
			});
		}
	},

	addEventListeners() {
		this.removeEventListeners();
		const {filtersView = {}} = this.parent;
		const {collection} = filtersView;

		if (collection) {
			// Filter offers
			this.listenTo(collection, 'filterCollection', this.onFiltersCollectionChanged);

			// Change minPrice, provider on card and sorting cards
			this.listenTo(collection, 'beforeFilterCollection', this.onBeforeFilterCollection);
		}
	},

	removeEventListeners() {
		const {filtersView = {}} = this.parent;
		const {collection} = filtersView;

		if (collection) {
			this.stopListening(collection);
		}
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);
		this.ui.hotelsResultContainer.empty();
		if (!this.isMapApiAvailable) this.ui.hotelsResultMapContainer.hide();
	},

	removeAlreadyRenderedViews() {
		const $container = this.ui.hotelsResultContainer;
		const renderedViews = $container.find('.b-hotel');
		const mapRenderedViews = this.ui.hotelsResultMapContainer.find('.b-hotel');
		if (renderedViews.length === 0 && mapRenderedViews.length === 0) return this.queueRender;
		let queueRender = this.queueRender;

		const process = (rv) => {
			const hNumber = $(rv).attr('data-hotel-number');
			queueRender = _.filter(queueRender, (v) => {
				if (v && v.hotel) {
					return v.hotel.number !== hNumber;
				}
				return false;
			});
		};

		_.each([...renderedViews, ...mapRenderedViews], process);

		return queueRender;
	},

	renderQueue(step = 1, $container = this.ui.hotelsResultContainer, renderOnScroll = false) {
		return new Promise((resolve) => {
			if (!this.queueRender.length) {
				resolve();
				return;
			}

			if (renderOnScroll) {
				this.queueRender = this.removeAlreadyRenderedViews();
			}

			this.renderProcess = true;

			let queue = this.queueRender.slice(0, step);
			_.each(queue, (view, i) => {
				_.defer(() => {
					$container.append(view.render({
						roomsCount: this.roomsCount,
					}).$el);

					if (i === queue.length - 1) {
						this.renderProcess = false;
						this.queueRender = this.queueRender.slice(step);

						queue = null;
						resolve();
					}
				});
			});
		});
	},

	renderFirstResults() {
		const COUNT = 10;
		let $tmpContainer = this.ui.hotelsResultContainer.clone().empty();

		this.renderQueue(COUNT, $tmpContainer).then(() => {
			this.ui.hotelsResultContainer.html($tmpContainer.get(0).children);
			$tmpContainer = null;
		});
	},

	silentRenderResults() {
		const COUNT = 10;

		this.renderQueue(COUNT, this.ui.hotelsResultContainer, true);
	},

	resetResults() {
		$('.b-tickets-container-hotels').removeClass('search-is-complete');
		this.ui.hotelsResultContainer.html('');
		this.ui.hotelsResultMapContainer.html('');

		this.hasTravelPolicy = (this.options.results.hasTravelPolicy === true);

		this.views = [];
		this.models = [];
		this.markers = [];
		this.mergeHotels = [];

		this.data = null;
		this.queueRender = [];
		this.renderProcess = false;
		this.searchFinished = false;

		this.notRenderedHotels = [];
		this.approvalOffers = [];

		this.parent.results = [];
		this.parent.renderFilters();

		this.closeResultPopup();
		this.clearCluster();
	},

	searchResultScroll() {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			const scrollOffset = window.scrollY;

			if (this.mapFixed) {
				const { top, right, left } = this.ui.mapContainer.offset();
				const offsetTop = this.fixedPosTop ? this.fixedPosTop : top;

				if (offsetTop - 40 <= scrollOffset) {
					if (!this.ui.mapContainer.hasClass('b-hotels__map--fixed')) {
						this.fixedPosTop = top;
						this.$('.b-hotels__map-wrapper').css({ height: this.ui.mapContainer.outerHeight(true) });
						this.ui.mapContainer.addClass('b-hotels__map--fixed').css({ top: 40, right, left });
					}
				} else if (this.ui.mapContainer.hasClass('b-hotels__map--fixed')) {
					this.mapUnexpand();
				}
			}

			if (!this.searchFinished) {
				return false;
			}

			const lLayoutHeight = $('.l-layout').height();
			const offset = Math.abs(scrollOffset - lLayoutHeight + window.innerHeight) << 0;

			if (offset < 400 && !this.renderProcess) {
				this.silentRenderResults();
			}

			return false;
		}, 100);
	},

	setCategoryHotels(category, view) {
		if (_.isEmpty(category) && this.category == null) {
			category = {value: 'CHEAPEST'};
		} else if (_.isEmpty(category) && this.category != null) {
			category = this.category;
		}

		// One view, when load offers
		const views = view != null ? [view] : _.filter(this.views, (v) => {
			return !_.isEmpty(v.model.get('hotelOffers')) && v.$el.hasClass('b-hotel__visible');
		});

		this.category = category;
		this.$el.removeClass('all-group-opened');

		if (this.category.value === 'ALL_ROOMS') {
			this.$el.addClass('all-group-opened');
		}

		_.each(views, (v) => {
			v.filterOffersProcess(true);
			v.orderOffersByCategory();
			if (v.$el.parents('.b-hotels__results').length > 0) v.renderPriceBlock();
		});

		if (view == null) {
			this.parent.sortingView.sort();
		}
	},

	addMarkersHotels(hotels = [], addInCluster = true) {
		if (!this.isMapApiAvailable) return;
		_.each(hotels, (h) => h && this.addMarkerHotel(h, addInCluster));
		const zoom = this.map.getZoom();
		this.map.setZoom(zoom + 0.000000000000001);
		this.map.setZoom(Math.round(zoom));
	},

	addMarkerHotel(hotel, addInCluster = true) {
		if (_.isEmpty(hotel) || hotel.address == null) {
			return this;
		}

		const {address} = hotel;
		const locationHotel = _.pick(address, ['latitude', 'longitude']);
		hotel.latLng = new window.google.maps.LatLng(locationHotel.latitude, locationHotel.longitude);
		hotel.marker = new this.hotelMarkersOverlay({
			position: hotel.latLng,
			map: this.map,
			args: hotel,
		});
		this.markers.push(hotel.marker);

		if (this.markerCluster == null) {
			this.initCluster();
		}

		if (addInCluster) {
			this.markerCluster.addMarker(hotel.marker);
		}

		return hotel.marker;
	},

	deepMergeHotel(hotel, hotel2) {
		hotel.name = this.mergeNotNull(hotel.name, hotel2.name);
		hotel.hotelStars = this.mergeNotNull(hotel.hotelStars, hotel2.hotelStars);
		hotel.numberOfNights = this.mergeNotNull(hotel.numberOfNights, hotel2.numberOfNights);
		hotel.address = this.mergeNotNull(hotel.address, hotel2.address);
		hotel.phones = this.mergeNotNull(hotel.phones, hotel2.phones);
		hotel.facilities = this.mergeNotNull(hotel.facilities, hotel2.facilities);
		hotel.description = this.mergeNotNull(hotel.description, hotel2.description);

		hotel.images = this.mergeByUid(hotel.images, hotel2.images);

		hotel.hotelOffers = Array.prototype.concat((hotel.hotelOffers || []), (hotel2.hotelOffers || []));

		return hotel;
	},

	// useFakeModel is true, if not need change current views model
	getMergeHotels(hotels = [], useFakeModel) {
		_.each(hotels, (hotel, i) => {
			if (hotel == null) {
				return;
			}
			if (_.find(this.views, v => v.hotel && v.hotel.number === '1582892511322') && hotel.number === '1582892511322') {
				logger.debug('eqHotel');
			}

			let eqHotel = _.find(this.views, (v) => v.hotel && v.hotel.number === hotel.number);

			if (eqHotel == null) {
				eqHotel = _.find(hotels, (v) => v && v.number === hotel.number && v !== hotel);
			}

			if (eqHotel != null) {
				// Hotel is initilize, rendered and have model,view
				if (eqHotel.model != null) {
					if (useFakeModel !== true) {
						const indexModel = _.findIndex(this.models, (m) => m === eqHotel.model);

						eqHotel.hotel = this.deepMergeHotel(hotel, eqHotel.hotel);
						eqHotel.model = new hotelModel({
							...eqHotel.hotel,
							hasTravelPolicy: this.hasTravelPolicy,
							originCityInfo: this.originCityInfo,
						});

						if (indexModel !== -1) {
							this.models[indexModel] = eqHotel.model;
							eqHotel.model.set('providerList', eqHotel.getHotelOffersProviderList()); // <- обновляем список поставщиков на основе новых офферов
							eqHotel.addEventListeners();
						}
						const hasOpenOffers = eqHotel.$el.hasClass('b-hotel--offers-is-open');
						const temp = eqHotel.$el.empty();
						if (temp[0].parentNode != null) {
							temp[0].parentNode.replaceChild(eqHotel.render.call(eqHotel, {
								roomsCount: this.roomsCount,
							}).el, temp[0]);
						} else {
							eqHotel.$el.detach();
							eqHotel.render.call(eqHotel, {
								roomsCount: this.roomsCount,
							});
						}

						eqHotel.renderPriceBlockTemplate(); // <- ререндерим блок с ценами ради обновления отображения поставщиков

						if (hasOpenOffers && eqHotel.el.parentNode != null) {
							eqHotel.$el.find('.b-hotel__select-offer').click();
						}
					} else {
						eqHotel.fakeHotel = this.deepMergeHotel(hotel, eqHotel.fakeHotel || eqHotel.hotel);
						eqHotel.fakeModel = new hotelModel({
							...eqHotel.fakeHotel,
							hasTravelPolicy: this.hasTravelPolicy,
							originCityInfo: this.originCityInfo,
						});
					}
				} else {
					eqHotel = this.deepMergeHotel(hotel, eqHotel);
				}

				delete hotels[i];
			}
		});

		return _.compact(hotels);
	},

	noResults() {
		const noTicketsPopup = new Widgets.Popup({
			content: L10N.get('hotels.noOffers'),
			type: 'danger',
			actions: [{
				label: L10N.get('errors.noTicketsButton'),
				action: () => {
					noTicketsPopup.hide();
				},
			}],
			onClose: () => {
				noTicketsPopup.hide();
				STATE.navigate(STATE.ROUTES.INDEX);
			},
		});
		noTicketsPopup.show();
	},

	// Utils
	mergeNotNull(obj, obj2) {
		return (obj != null ? obj : obj2);
	},

	mergeByUid(col, col2, field) {
		if (field != null) {
			if (col != null) {
				col = col[field];
			}
			if (col2 != null) {
				col2 = col2[field];
			}
		}
		if (col != null && col2 == null) {
			return col;
		} else if (col == null && col2 != null) {
			return col2;
		}

		_.each(col2, (el) => {
			if (_.findWhere(col, {uid: el.uid}) == null) {
				col.push(el);
			}
		});

		return col;
	},

	getFiltersValue(collection = [], fields = []) {
		const models = !_.isEmpty(fields)
			? _.filter(collection, (model) => model && fields.includes(model.get('field')))
			: collection;
		const values = {};

		_.each(models, (model) => {
			const field = model.get('field');
			let value = model.get('value');

			if (model.get('values') != null) {
				const data = _.map(_.filter(model.get('values').models, (m) => m.get('value') === true), (el) => ({
					uid: el.get('uid'),
					value: el.get('value'),
				}));

				value = {};
				_.each(data, (d) => {
					value[d.uid] = d.value;
				});
			}

			values[field] = value;

			if (_.isObject(values[field])) {
				if (_.isArray(values[field]) && model.get('min') === values[field][0] && model.get('max') === values[field][1]) {
					// All range for slider
					delete values[field];
				} else if (_.size(values[field]) === _.size(model.get('values') && model.get('values').models)) {
					// Select all variants for select
					delete values[field];
				}
			}

			if (_.isEmpty(values[field]) && typeof values[field] !== 'boolean') {
				delete values[field];
			}
		});

		return values;
	},

	selectHotelOnMap(hotel) {
		if (!this.isMapApiAvailable) return;
		let view = _.find(this.views, (v) => v.hotel.number === hotel.number);

		if (view == null) {
			const findedHotel = _.find(this.notRenderedHotels, (h) => h.number === hotel.number);

			if (findedHotel == null) {
				return;
			}

			const model = new hotelModel({
				...findedHotel,
				hasTravelPolicy: this.hasTravelPolicy,
				originCityInfo: this.originCityInfo,
			});
			view = new hotelView({
				index: 0,
				hotel: findedHotel,
				model,
				parent: this,
			});
			view.render();
			this.ui.hotelsResultMapContainer.html(view.$el);
		}

		if (!view.$el.is(':visible')) {
			view.render(); // if hotel was loaded but not rendered yet
			this.ui.hotelsResultMapContainer.html(view.$el);
		}

		view.toggleOffers();
	},

	showHotelOnMap(hotel) {
		if (_.isEmpty(hotel) || hotel.address == null || !this.isMapApiAvailable) {
			return;
		}

		const {address} = hotel;
		const locationHotel = _.pick(address, ['latitude', 'longitude']);
		const latLng = new window.google.maps.LatLng(locationHotel.latitude, locationHotel.longitude);

		const $mapWrapper = this.$('.b-hotels__map-wrapper');
		$mapWrapper.removeClass('hotels-map-closed');
		$mapWrapper.removeClass('dn');
		if (!this.mapFixed) {
			this.fixMapTop();
		}
		this.map.panTo(latLng);
		this.map.setZoom(16);
	},

	// Events
	addOffer(e, {offers, hotel}, _exclude = false) {
		const $target = e != null ? this.$el.find(e.currentTarget) : null;
		let exclude = _exclude;

		if ($target != null && $target.length !== 0) {
			const token = (_.size(offers) === 1) ? offers[0].token : hotel.number;

			if (!$target.hasClass('b-ticket-offer__cancel')) {
				const width = $target.outerWidth();

				$target.attr('data-original-caption', _.escape($target.html()));
				$target.attr('data-token', token);
				$target.text(L10N.get('hotels.removeFromAdded'));

				$target.addClass('b-ticket-offer__cancel');
				$target.css('width', width);
			} else {
				const originalCaption = $target.attr('data-original-caption');
				exclude = true;

				$target.html(_.unescape(originalCaption));
				$target.removeAttr('data-original-caption').removeClass('b-ticket-offer__cancel');
				$target.removeAttr('data-token');
				$target.css('width', '');
			}
		}

		if (e.isTrigger == null) {
			if (!$target.hasClass('b-hotel__summary-select')) {
				offers = _.uniq(offers, (o) => o.token);
			} else {
				offers = _.map(offers, (o, idx) => {
					o = _.clone(o);
					o.roomIndex = ++idx;
					return o;
				});
			}
			const indexHotel = _.findIndex(this.approvalOffers, (o) => o != null && o.hotel.number === hotel.number);
			if (indexHotel !== -1) {
				const hotelOffer = this.approvalOffers[indexHotel];

				if (_.size(offers) === 1) {
					const indexOffer = _.findIndex(hotelOffer.offers, (o) => o != null && o.token === offers[0].token);

					if (indexOffer !== -1) {
						hotelOffer.offers.splice(indexOffer, 1);

						if (_.isEmpty(hotelOffer.offers)) {
							this.approvalOffers.splice(indexHotel, 1);
						}
					} else {
						hotelOffer.offers.push(offers[0]);
					}
				} else {
					if (exclude !== true) {
						hotelOffer.hotel.offers = offers;
					} else {
						this.approvalOffers.splice(indexHotel, 1);
					}
				}
			} else if (exclude !== true) {
				this.approvalOffers.push({offers, hotel});
			}
		}

		/*
			здесь показываем плашку "К согласованию".
			Функция addOffer() вызывается и при нажатии "Удалить" на карточке отеля, просто в таком случае
			мы не попадаем в условие this.approvalOffers.length и не создаем новый попап.
		*/

		this.$el.find('.b-ticket-popup-offers').detach();
		if (this.approvalOffers.length) {
			this.$el.append(HotelsPopupOffers({
				data: this.approvalOffers,
			}));
			this.$el.find('.b-ticket-popup-offers').on('click', this.sendOffers.bind(this));
		}
	},

	sendOffers(e) {
		if (e != null) {
			e.preventDefault();
		}

		// First ticket card for sync width and postion right
		const $ticketContainer = this.$el.find('.b-hotel.b-hotel__visible').first();
		const order = this.options != null &&
			this.options.parent != null &&
			!_.isEmpty(this.options.parent.configuration) &&
			this.options.parent.configuration.order;

		if (this.ticketModalOffers == null) {
			this.ticketModalOffers = new HotelsModalOffersView({
				showGdsAccountName: this.showGdsAccountName,
				data: this.approvalOffers,
				parent: this,
				$ticketContainer,
				order,
			});
		} else {
			this.ticketModalOffers.setOptions({data: this.approvalOffers, $ticketContainer}).render();
		}
	},

	showAllHotels(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.closeResultPopup();
		this.ui.hotelsResultMapContainer.empty();

		_.each(_.filter(this.views, (v) => v.fakeModel != null), (v) => {
			const index = _.findIndex(this.models, (m) => m === v.model);

			v.hotel = v.fakeHotel;
			v.model = v.fakeModel;

			this.models[index] = v.model;
			v.addEventListeners();

			delete v.fakeHotel;
			delete v.fakeModel;
		});

		for (let i = 0; i < this.notRenderedHotels.length; i++) {
			const hotel = this.notRenderedHotels[i];
			const model = new hotelModel({
				...hotel,
				hasTravelPolicy: this.hasTravelPolicy,
				originCityInfo: this.originCityInfo,
			});
			const view = new hotelView({index: i, hotel, model, parent: this});

			this.models.push(model);
			this.views.push(view);
		}

		this.notRenderedHotels = [];

		this.parent.renderFilters();
		this.parent.applyFilters();

		this.parent.sortingView.sort();
	},

	closeResultPopup(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.ui.searchCountContainer.addClass('dn');
		this.ui.searchCountContainer.find('.b-hotels__search-all').addClass('dn');

		this.$el.find('.b-ticket-popup-offers').detach();
	},

	resetFilters(e) {
		if (e != null) {
			e.preventDefault();
		}

		const {filtersView} = this.parent || {};

		if (filtersView != null) {
			filtersView.resetFilters();
		}
	},

	mapClose(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.mapUnexpand();
		this.resetControls();
		this.mapFixed = false;
		this.isExpanded = false;
		this.$('.b-hotels__map-wrapper').addClass('hotels-map-closed');
		if (STATE.checkViewport('(max-width: 768px)')) this.$('.b-hotels__map-wrapper').addClass('dn');
	},

	mapOpen(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.mapUnexpand();
		this.$('.b-hotels__map-wrapper').removeClass('hotels-map-closed');
		this.$('.b-hotels__map-wrapper').removeClass('dn');
	},

	mapUnexpand() {
		$('body, html').removeClass('overflow');
		this.$el.removeClass('hotels-map-expanded hotels-map-fixed-top');
		this.ui.mapContainer
			.removeClass('b-hotels__map--fullscreen b-hotels__map--fixed')
			.css({top: '', left: '', right: '', bottom: ''});

		this.$('.b-hotels__map-wrapper').css({height: ''});
	},

	resetControls() {
		this.$('.hotels-map-fix')
			.removeClass('active')
			.attr('data-original-title', L10N.get('hotels.fixMap'))
			.tooltip('hide');
		this.$('.hotels-map-expand')
			.removeClass('active')
			.attr('data-original-title', L10N.get('hotels.fullscreenMode'))
			.tooltip('hide');
	},

	mapExpand(e) {
		if (e != null) {
			e.preventDefault();
		}

		if (this.map != null) {
			this.mapUnexpand();
			this.resetControls();

			this.mapFixed = false;
			this.isExpanded = !this.isExpanded;
			this.$el[(this.isExpanded ? 'addClass' : 'removeClass')]('hotels-map-expanded');

			if (this.isExpanded) {
				$('body, html').addClass('overflow');
				$(window).scrollTop(0);

				_.defer(() => {
					const topOffset = this.ui.mapContainer.offset().top;
					this.ui.mapContainer
						.addClass('b-hotels__map--fullscreen')
						.css({top: topOffset});
				});
			}

			this.$('.hotels-map-fix')[(this.isExpanded ? 'addClass' : 'removeClass')]('active');
			this.$('.hotels-map-expand').attr('data-original-title', (this.isExpanded ? L10N.get('hotels.compactMode') : L10N.get('hotels.fullscreenMode')));
			this.$('.hotels-map-expand').tooltip('hide');

			const mapCenter = this.map.getCenter();
			try {
				window.google.maps.event.trigger(this.map, 'resize');
			} catch (ex) {
				throw ex;
			}
			this.map.setCenter(mapCenter);
		}
	},

	fixMapTop(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.mapFixed = !this.mapFixed;
		this.$('.hotels-map-fix')[(this.mapFixed ? 'addClass' : 'removeClass')]('active');
		this.$('.hotels-map-fix').tooltip('hide');

		if (this.mapFixed) {
			_.defer(this.searchResultScroll.bind(this));
			this.$el.addClass('hotels-map-fixed-top');
			this.$('.hotels-map-fix').attr('data-original-title', L10N.get('hotels.unFixMap'));
		} else {
			this.mapUnexpand();
			this.resetControls();
			this.$el.removeClass('hotels-map-fixed-top');
			this.$('.hotels-map-fix').attr('data-original-title', L10N.get('hotels.fixMap'));
		}
	},

	remove() {
		this.stopListening(this, 'applyEventListeners', this.addEventListeners);
		this.removeEventListeners();
		BaseView.prototype.remove.call(this);
	},

});
