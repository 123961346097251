// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import TrainView from '@/blocks/elements/b-train/index';
import TrainModel from '@/blocks/elements/b-train/model';
import SortingView from '@/blocks/elements/b-sorting/index';
import FiltersView from '@/blocks/elements/b-filters/b-filters-trains/index';
import TrainsSearchFormView from '@/blocks/elements/b-train/b-train-search-form/index';
import TrainsFiltersCollection from '@/blocks/elements/b-filters/b-filters-trains/collection';
import TrainPopupOffers from '@/blocks/elements/b-train/b-train-popup-offers/b-train-popup-offers';
import TrainModalOffersView from '@/blocks/elements/b-train/b-train-popup-offers/index';
import SearchRouteModel from '@/blocks/elements/b-search-route/model';
import SideClickOutsideAdapter from '@/blocks/utils/b-side-click-outside-adapter';

import Backbone from 'backbone';
import axios from 'axios';
import $ from 'jquery';

const TicketPageView = BasePageView.extend({

	tickets: new Backbone.Collection(),

	pricingParameters: null,
	seatsAmount: null,

	ui: {
		content: '.b-tickets-container',
		controls: '.l-layout__content-controls',
		sidebar: '.l-layout__content-side',
		top: '.l-layout__top',
	},

	preinitialize(options) {
		BasePageView.prototype.preinitialize.call(this, options);
		this.sideOutsideClickAdapter = new SideClickOutsideAdapter({
			applyHandlerOnStart: window.matchMedia('(min-width: 768px)').matches,
		});
		this.windowEventListenerList.push(
			{
				mediaQuery: '(max-width: 768px)',
				name: 'change',
				callback: this.adjustMobileTemplate.bind(this),
				isMatchMedia: true,
			},
			{
				name: 'change',
				callback: this.sideOutsideClickAdapter.handleSideOutsideClickHandler,
				mediaQuery: '(min-width: 768px)',
				isMatchMedia: true,
			},
			{
				name: 'keyup',
				callback: this.contentSideEscHandler.bind(this),
			},
		);
	},

	initialize(options) {
		this.options = options;

		this.approvalAllowed = options.approvalAllowed;
		this.issueAllowed = options.issueAllowed;
		this.approvalOffers = [];

		const searchParameters = STATE.getSearchParametrs();

		this.isRoundTrip = searchParameters.routeType === 'ROUNDTRIP';
		this.pricingParameters = {
			routeInfo: null,
			routeInfoBack: null,
		};

		this.passengersTypes = searchParameters.passengersTypes;
		this.seatsAmount = 0;

		const passengersTypesKeys = Object.keys(this.passengersTypes);
		if (passengersTypesKeys.length === 0 && searchParameters.passengers != null) {
			// corp travellers always ADULT's
			this.passengersTypes = {
				ADULT: searchParameters.passengers.length,
			};
			this.seatsAmount = searchParameters.passengers.length;
		} else {
			passengersTypesKeys.forEach(k => {
				if (k === 'ADULT' || k === 'CHILD') {
					this.seatsAmount += this.passengersTypes[k];
				}
			});
		}

		this.results = options.results || options;
		this.routeId = parseInt(options.routeId, 10);
		this.hasTravelPolicy = (this.results.hasTravelPolicy === true);

		// restore prev selected price components
		if (this.routeId === 2) {
			const step1PricingParameters = STORE.get(STATE.ROUTES.RAILWAYS_PASSENGERS);
			if (step1PricingParameters != null && step1PricingParameters.routeInfo != null) {
				this.pricingParameters = step1PricingParameters;
				this.selectedVariantGdsAccountName = step1PricingParameters.routeInfo.train && step1PricingParameters.routeInfo.train.gdsAccountName || null;
			} else {
				this.render();
				STATE.navigate('trains/tickets/route/1');
				return;
			}
		} else {
			STORE.set(STATE.ROUTES.RAILWAYS_PASSENGERS, this.pricingParameters);
		}

		this.render();
		this.renderTrains();
		this.addEventListeners();
	},

	delegateEvents(...args) {
		BasePageView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BasePageView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(matches) {
		if (_.isObject(matches)) matches = matches.matches;
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			_.each(this.trainViews, (v) => v && v.adjustMobileTemplate(matches));
		}, 100);
	},

	contentSideEscHandler(e) {
		if (e.code === 'Escape') {
			$('.l-layout').removeClass('show-filters');
		}
	},

	addEventListeners() {
		this.listenTo(this.getPageModel(), 'change:isFilteringResults', (model, v) => {
			this.ui.content[v ? 'addClass' : 'removeClass']('g-process2');
		});
	},

	render(...args) {
		BasePageView.prototype.render.apply(this, args);

		this.trainsSearchFormView = new TrainsSearchFormView({
			parent: this,
		});

		if (!STATE.getIsFromHash()) {
			if (this.trainsSearchFormView) this.ui.top.append(this.trainsSearchFormView.$el);
		}
		if (this.results.doSearch) {
			this.findTrainByNumber = this.results.findTrainByNumber;
			this.trainsSearchFormView.search();
		}
		this.ui.controls.append(`<div class="l-content-table-title-rail">${L10N.get('trains.fullPrice')}</div>`);

		this.ui.content.addClass('b-tickets-container-rail');
	},

	renderTrains() {
		this.trainModels = [];
		this.trainViews = [];

		this.ui.content.empty();
		this.ui.sidebar.empty();

		if (this.results && this.results.trains) {
			let resultTrains = null;
			switch (this.routeId) {
				case 1:
					resultTrains = this.results.trains;
					break;
				case 2:
					resultTrains = this.results.trainsBack;
					if (this.selectedVariantGdsAccountName) {
						resultTrains = _.filter(resultTrains, (t) => {
							return t.gdsAccountName === this.selectedVariantGdsAccountName;
						});
					}
					break;
				default:
					resultTrains = this.results.trains;
					break;
			}
			const specialServiceClasses = this.results.specialServiceClasses;

			if (_.size(resultTrains) === 0) {
				const noTicketsPopup = new Widgets.Popup({
					content: L10N.get('errors.noTickets'),
					type: 'danger',
					actions: [{
						label: L10N.get('errors.noTicketsButton'),
						action: () => {
							noTicketsPopup.hide();
						},
					}],
					onClose: () => {
						noTicketsPopup.hide();
						STATE.navigate(STATE.ROUTES.INDEX);
					},
				});
				noTicketsPopup.show();
			}

			this.trainModels = resultTrains.map(item => new TrainModel(item, {
				hasTravelPolicy: this.hasTravelPolicy,
				currencyCode: this.results.currencyCode,
			}));
			this.filtersCollection = new TrainsFiltersCollection(null, {
				routeId: this.routeId,
				tickets: this.trainModels,
				currencyCode: this.results.currencyCode,
			});

			this.trainModels.forEach(item => {
				const trainView = new TrainView({
					parent: this,
					model: item,
					currencyCode: this.results.currencyCode,
					approvalAllowed: this.approvalAllowed,
					issueAllowed: this.issueAllowed,
					specialServiceClasses,
				});
				this.ui.content.append(trainView.$el);
				this.trainViews.push(trainView);
			});

			if (this.trainModels != null && this.trainModels.length !== 0) {
				const filterView = new FiltersView({
					collection: this.filtersCollection,
				});
				const sortingView = new SortingView({
					container: this.ui.content,
					views : this.trainViews,
					hasTravelPolicy: this.hasTravelPolicy,
					defaultSortOption: this.options.searchResultSortType,
					type: 'RAILWAYS',
				});
				sortingView.sort();
				filterView.addSorting(sortingView);
				this.ui.sidebar.append(filterView.el);
				this.ui.sidebar.append(`<div class="l-offer-preview-container"></div>`);
				this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
			}
			if (this.findTrainByNumber != null) {
				const $train = this.ui.content.find(`[data-number="${this.findTrainByNumber}"]`);
				if ($train.length) {
					$(window).scrollTop($train.offset().top);
				}
				this.findTrainByNumber = null;
			}
		}
	},

	process(routeInfo) {
		STATE.hideOfferPreview();
		if (this.routeId === 1) {
			this.pricingParameters.routeInfo = routeInfo;
			STORE.set(STATE.ROUTES.RAILWAYS_PASSENGERS, this.pricingParameters);

			if (this.isRoundTrip) {
				window.scrollTo(0, this.ui.content.offset().top - 10);
				STATE.navigate('trains/tickets/route/2');
			} else {
				STATE.navigate('trains/passengers');
			}
		} else if (this.routeId === 2) {
			this.pricingParameters.routeInfoBack = routeInfo;
			STORE.set(STATE.ROUTES.RAILWAYS_PASSENGERS, this.pricingParameters);
			STATE.navigate('trains/passengers');
		} else {
			throw new Error(`Unknown step ${this.routeId}`);
		}
	},

	addOffer(e, routeInfo, _exclude = false) {
		const $target = _.size(this.ui.content.find(e.currentTarget)) ? this.ui.content.find(e.currentTarget) : this.ui.sidebar.find(e.currentTarget);
		let exclude = _exclude;

		if ($target.length !== 0) {
			if (!$target.hasClass('b-ticket-offer__cancel')) {
				const width = $target.outerWidth();

				$target.attr('data-original-caption', _.escape($target.html()));
				$target.attr('data-token', _.escape(routeInfo.token));
				$target.text(L10N.get('trains.removeFromApproval'));

				$target.addClass('b-ticket-offer__cancel');
				$target.css('width', width);
			} else {
				const originalCaption = $target.attr('data-original-caption');
				exclude = true;

				$target.html(originalCaption);
				$target.removeAttr('data-original-caption').removeClass('b-ticket-offer__cancel');
				$target.removeAttr('data-token');
				$target.css('width', '');
			}
		}

		const index = _.findIndex(this.approvalOffers, (o) => o != null && o.token === routeInfo.token);
		if (index !== -1) {
			this.approvalOffers.splice(index, 1);
		}

		if (exclude !== true) {
			this.approvalOffers.push(routeInfo);
		}

		this.ui.content.find('.b-ticket-popup-offers').detach();
		if (this.approvalOffers.length) {
			this.ui.content.append(TrainPopupOffers({
				data: this.approvalOffers,
				totalPrice: routeInfo.totalPrice,
			}));
			this.ui.content.find('.b-ticket-popup-offers').on('click', this.sendOffers.bind(this));
		}
	},

	sendOffers(e) {
		if (e != null) {
			e.preventDefault();
		}

		// First ticket card for sync width and postion right
		const $ticketContainer = this.ui.content.find('.b-train-ticket__wrapper.b-train-ticket__visible').first();
		const { order } = this.options || {};

		if (this.ticketModalOffers == null) {
			this.ticketModalOffers = new TrainModalOffersView({ data: this.approvalOffers, parent: this, $ticketContainer, order });
		} else {
			this.ticketModalOffers.setOptions({ data: this.approvalOffers, $ticketContainer }).render();
		}
	},

	remove() {
		if (this.sideOutsideClickAdapter) {
			this.sideOutsideClickAdapter.removeListener();
		}
		BasePageView.prototype.remove.call(this);
	},

}, {

	loadFromHash(hash) {
		// CL|ECONOMY!D|true!R|2000000|2004000|2023-05-20!R|2004000|2000000|2023-06-03!PT|ADULT|1!RT|ROUNDTRIP!C|false +
		// !C|07e9360d-542d-4518-a494-87c5589f0d3a!P|!S|RAIL
		STATE.showLoader();
		return new Promise(async (resolve) => {
			try {
				const attrs = {
					routes: [],
					passengersTypes: {},
					direct: false,
					routeType: 'CUSTOM',
					searchType: 'RAIL',
				};

				STATE.checkHash(hash, 'RAIL');
				STATE.getParsedHash(hash, attrs);

				attrs.routes = attrs.routes.sort((a, b) => {
					return new Time(a.date).getMoment().isAfter(new Time(b.date).getMoment()) ? 1 : -1;
				});

				const appSearchModel = STATE.getSearchModel();

				if (attrs.classOfService) {
					appSearchModel.set('classOfService', attrs.classOfService);
				}

				let leg_ = appSearchModel.get('routes').pop();
				while (leg_) {
					leg_.destroy();
					leg_ = appSearchModel.get('routes').pop();
				}

				let routes = attrs.routes;
				if (routes.length === 2) {
					if (routes[0].departure === routes[1].arrival && routes[0].arrival === routes[1].departure) {
						routes[0].dateFrom = routes[1].date;
						routes = [routes[0]];
						appSearchModel.set('routeType', 'ROUNDTRIP');
					}
				}

				for (const leg of routes) {
					appSearchModel.addRoute(new SearchRouteModel({
						departure: await STATE.getLocations(leg.departure, attrs.searchType, 20),
						arrival: await STATE.getLocations(leg.arrival, attrs.searchType, 20),
						dateTo: new Time(leg.date).toServerDate(),
						dateFrom: leg.dateFrom ? new Time(leg.dateFrom).toServerDate() : undefined,
						departureTimeWindowStart: leg.departureTimeWindowStart,
						departureTimeWindowEnd: leg.departureTimeWindowEnd,
					}));
				}

				if (!_.isEmpty(attrs.passengersTypes)) {
					Object.keys(attrs.passengersTypes).forEach(key => {
						appSearchModel.get('passengersTypes').set(key, attrs.passengersTypes[key]);
					});
				}

				if (attrs.passengers && attrs.passengers.length) {
					Object.keys(attrs.passengers).forEach(key => {
						appSearchModel.get('passengersTypes').set(key, attrs.passengers[key]);
					});
				}

				appSearchModel.set('direct', attrs.direct);
				appSearchModel.set('changed', attrs.changed || false);
				appSearchModel.get('serviceTypes').reset([{uid: attrs.searchType, caption: attrs.searchType}]);
				const url = '/midoffice/ibecorp-b2b/search/getTransport';
				const params = appSearchModel.toJSON();
				STORE.set(STATE.ROUTES.MAIN_SEARCH, params.parameters, 14400);
				axios.post(url, params, appSearchModel)
					.then(result => {
						STATE.setTrainsSearchResults(result.data.result);
						STATE.removeHeader();
						resolve({fromHash: true});
					}).catch(e => {
						STORE.remove(STATE.ROUTES.TICKETS);
						logger.error(e);
						resolve();
					});
			} catch (e) {
				logger.error(e);
				resolve();
			}
		});
	},

	load(params) {
		return new Promise((resolve) => {
			const results = STATE.getTrainsSearchResults();

			if (_.isEmpty(results)) {
				STATE.navigate(STATE.ROUTES.INDEX);
				return;
			}

			if (params.fromHash) {
				STATE.setIsFromHash(true);
				STATE.disableFirstStep();
			}

			if (_.isEmpty(results.rail)) {
				const noTicketsPopup = new Widgets.Popup({
					content: L10N.get('errors.noTickets'),
					type: 'danger',
					actions: [{
						label: L10N.get('errors.noTicketsButton'),
						action: () => {
							noTicketsPopup.hide();
						},
					}],
					onClose: () => {
						noTicketsPopup.hide();
						STATE.navigate(STATE.ROUTES.INDEX);
					},
				});
				noTicketsPopup.show();

				resolve({});
			}

			if (results.order != null) {
				STATE.layout.header.setAdditionalOrder(results.order);
			}
			const {searchResultSortType} = results;
			const approvalAllowed = results != null && results.approvalAllowed === true;
			const issueAllowed = results != null && results.issueAllowed === true;
			resolve({
				results: results.rail[0],
				searchResultSortType,
				routeId: params.routeId,
				approvalAllowed,
				issueAllowed,
				order: results.order,
				initScrollTopButton: true,
			});
		});
	},
});

export default TicketPageView;
