// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import Login from '@/blocks/pages/b-login/view';

import Backbone from 'backbone';
import axios from 'axios';

export default BaseView.extend({

	template,

	events: {
		'click .js-registration-link': 'showRegistrationForm',
		'click .b-input__select': 'selectValidation',
		'click .js-restore-link': 'showRestoreForm',
		'click .js-auth-link': 'showAuthForm',
		'click .js-register': 'register',
		'click .js-restore': 'restore',
		'click .js-sign-in': 'login',
		'click .js-logout': 'logout',
	},

	elm: {
		loginContainer: '.js-login-container',
		registerButton: '.js-register',
		restoreButton: '.js-restore',
		logoutButton: '.js-logout',
		loginButton: '.js-sign-in',
		loginButtonContainer: '.js-login-button-container',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize() {
		STATE.cabinetView = this;
		if (STATE.getSettings() !== null && !STATE.getSettings().generalSettings.personalAccountEnabled) return;
		if (STATE.getIsFromHash()) return;
		const currentRoute = STATE.router.current(Backbone.history.getFragment());

		this.sections.forEach(s => {
			if (s.routes && Array.isArray(s.routes)) {
				s.current = s.routes.includes(currentRoute) || (currentRoute || []).includes(s.uid);
			}
		});
		this.render();
	},

	render(...args) {
		const {cabinetMenuSettings = {}} = STATE.getSettings() || {};

		if (STATE.checkSiteType('B2C')) {
			this.sections = _.filter(this.sections, (s) => ['PASSENGERS', 'SEARCH', 'ORDERS', 'USER_DATA'].includes(s.uid));
			BaseView.prototype.render.apply(this, args);

			if (STATE.getLoggedInUser() != null) {
				this.$(this.elm.logoutButton).removeClass('dn');
				return;
			}

			this.restoreDisabled = true;
			this.loginDisabled = true;
			this.registerDisabled = true;
			this.drawLogin();
			const loginForm = STATE.loginView.$el.find('.p-login');
			this.$(this.elm.loginContainer).html(loginForm);
			this.$(this.elm.loginButton).removeClass('dn');
			STATE.loginView.addEventListeners.call(this);
			this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
			STATE.loginView.validation.call(this, STATE.loginView.model);

			return;
		}
		this.isCabinetProfileOnSearchRoute(cabinetMenuSettings.allowedSections);

		this.sections = _.filter(this.sections, (s) => {
			return _.some(cabinetMenuSettings.allowedSections, (el) => _.isObject(el) && el.uid === s.uid);
		});

		this.updateReportAppLink(cabinetMenuSettings);

		BaseView.prototype.render.apply(this, args);
		this.$(this.elm.logoutButton).removeClass('dn');
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	rerender(...args) {
		this.$el.empty();
		this.render(args);
	},

	sections: [
		{
			uid: 'SEARCH',
			title: L10N.get('cabinet.menu.search'),
			disable: false,
			current: false,
			routes: ['', '/'],
			path: '#/',
			classes: 'search-section',
		},
		{
			uid: 'SCHEDULE',
			title: L10N.get('cabinet.menu.schedule'),
			disable: false,
			current: false,
			routes: ['schedule'],
			path: '#/schedule',
			iconClassName: 'g-icon-schedule',
		},
		{
			uid: 'ORDERS',
			title: L10N.get('cabinet.menu.orders'),
			disable: false,
			current: false,
			routes: ['cabinetOrders', 'cabinetOrder'],
			path: '#/cabinet/orders',
			iconClassName: 'g-icon-clipboard',
		},
		{
			uid: 'BUSINESS_TRIP',
			title: L10N.get('cabinet.menu.trips'),
			disable: false,
			current: false,
			routes: [],
			path: '#/cabinet/trips',
			iconClassName: 'g-icon-briefcase',
		},
		{
			uid: 'EMPLOYEES',
			title: L10N.get('cabinet.menu.employees'),
			disable: false,
			current: false,
			routes: ['cabinetEmployees', 'cabinetEmployee'],
			path: '#/cabinet/employees',
			iconClassName: 'g-icon-users',
		},
		{
			uid: 'SALES',
			title: L10N.get('cabinet.menu.products'),
			disable: false,
			current: false,
			routes: ['cabinetProducts'],
			path: '#/cabinet/products',
			iconClassName: 'g-icon-shopping-bag',
		},
		{
			uid: 'FINANCES',
			title: L10N.get('cabinet.menu.finance'),
			disable: false,
			current: false,
			routes: ['cabinetFinance'],
			path: '#/cabinet/finance',
			iconClassName: 'g-icon-dollar-sign',
		},
		{
			uid: 'CONTRACTS_AND_FEES',
			title: L10N.get('cabinet.menu.contracts'),
			disable: false,
			current: false,
			routes: ['contracts'],
			path: '#/contracts',
			iconClassName: 'g-icon-file',
		},
		{
			uid: 'REPORT',
			title: L10N.get('cabinet.menu.reports'),
			disable: false,
			current: false,
			routes: [],
			iconClassName: 'g-icon-file-text',
		},
		{
			uid: 'PASSENGERS',
			title: L10N.get('cabinet.menu.passengers'),
			disable: false,
			current: false,
			routes: ['cabinetPassengers', 'cabinetPassenger'],
			path: '#/cabinet/passengers',
			iconClassName: 'g-icon-seat',
		},
		{
			uid: 'USER_DATA',
			title: L10N.get('cabinet.menu.profile'),
			disable: false,
			current: false,
			routes: ['cabinetProfile'],
			path: '#/cabinet/profile',
			iconClassName: 'g-icon-user',
		},
	],

	/* Подменяем ссылку на отчетное приложение */
	updateReportAppLink(settings) {
		if (settings.reportsLink) {
			const reportSection = this.sections.find((this.sections, (s) => s.uid === 'REPORT'));
			if (reportSection) {
				reportSection.title = L10N.get('cabinet.menu.reportsApp');
				reportSection.path = settings.reportsLink;
				reportSection.target = '_reports';
			}
		}
	},

	isCabinetProfileOnSearchRoute(allowedSections = []) {
		const searchSection = _.find(allowedSections, (el) => el.uid === 'SEARCH');
		if (!searchSection) {
			const profileSection = _.find(allowedSections, (el) => el.uid === 'USER_DATA');
			if (profileSection) {
				profileSection.routes = ['', '/'];
				profileSection.path = '#/';
			}
		}
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (STATE.checkSiteType('B2B')) return this;
			if (!STATE.getSettings().generalSettings.personalAccountEnabled) return this;
			if (_.isObject(matches)) matches = matches.matches;

			const loginView = STATE.loginView;
			const $currentViewEl = _.find([loginView.restoreView, loginView.authView, loginView.registrationView, loginView.companyView], (v) => {
				return v && v.$el.css('display') !== 'none';
			}).$el;
			const $loginButtonContainer = this.$(this.elm.loginButtonContainer).length ? this.$(this.elm.loginButtonContainer).detach() :
				this.$prevViewEl.find(this.elm.loginButtonContainer).detach();

			this.$prevViewEl = $currentViewEl;

			if (matches) {
				if ($currentViewEl.find('.p-login__form-restore').length) return $currentViewEl.find('.p-login__form-restore').append($loginButtonContainer);
				$currentViewEl.append($loginButtonContainer);
			} else {
				this.$prevViewEl = this.$el.append($loginButtonContainer);
			}

			$loginButtonContainer.toggleClass('static-flow', matches);

			return this;
		}, 100);
	},

	logout(e) {
		this.disableElements(e);
		return axios.get('/midoffice/ibecorp-b2b/authorization/logout').then(() => {
			STATE.logout();
		});
	},

	login(e) {
		this.disableElements(e);
		STATE.loginView.login(e)
			.then((noValidation) => {
				if (noValidation) {
					_.each(_.compact([STATE.router, STATE.getPageView()]), (el) => el.destroy());

					STATE.APP.initialize();
				}
			});
	},

	register(e) {
		this.disableElements(e);
		STATE.loginView.register(e);
	},

	restore(e) {
		this.disableElements(e);
		STATE.loginView.restore(e, this.$(this.elm.loginContainer))
			.then(() => {
				this.$(this.elm.restoreButton).addClass('dn');
			});
	},

	showRegistrationForm(e) {
		this.$(this.elm.restoreButton).addClass('dn');
		this.$(this.elm.loginButton).addClass('dn');
		this.$(this.elm.registerButton).removeClass('dn');
		STATE.loginView.showRegistrationForm(e);
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	selectValidation(e) {
		STATE.loginView.selectValidation(e);
	},

	showRestoreForm(e) {
		this.$(this.elm.registerButton).addClass('dn');
		this.$(this.elm.loginButton).addClass('dn');
		this.$(this.elm.restoreButton).removeClass('dn');
		STATE.loginView.showRestoreForm(e);
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	showAuthForm(e) {
		this.$(this.elm.restoreButton).addClass('dn');
		this.$(this.elm.registerButton).addClass('dn');
		this.$(this.elm.loginButton).removeClass('dn');
		STATE.loginView.showAuthForm(e);
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	drawLogin() {
		if (STATE.loginView != null) STATE.loginView.$el.empty();
		STATE.loginView = new Login();
	},

	deleteCookie() {
		document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	},
});
