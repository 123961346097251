// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	ui: {
		progressBar: '.b-search-progress-bar__bar',
		spinner: '.b-search-progress-bar__spinner',
	},

	initialize(options) {
		this.options = options;
		this.parent = this.options.parent;
		this.model = this.options.model;

		this.render();
	},

	move() {
		this.percentage = 10;
		this.barSlider = this.ui.progressBar.find('span');

		this.id = setInterval(this.frame.bind(this, 1000), 100);
	},

	frame(interval) {
		if (this.percentage === 60) {
			clearInterval(this.id);
			this.percentage++;
			if (interval) {
				this.id = setInterval(this.frame.bind(this, interval), 1000);
			} else {
				this.id = setInterval(this.frame.bind(this), 1000);
			}
		} else if (this.percentage === 100) {
			clearInterval(this.id);
			this.barSlider.css('width', `${this.percentage}%`);
		} else {
			this.percentage++;
			this.barSlider.css('width', `${this.percentage}%`);
		}
	},

	destroyView() {
		this.remove();
		return null;
	},
});
