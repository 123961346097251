module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var createdDate = new Time(order.created).format('DD.MM.YY');
	var limitDate = new Time(reservation.timeLimit).format('DD.MM.YYYY-HH:mm');

	var selectedTab = selectedTab || 'TICKETS';

	var firstSegment, lastSegment, isVisaNeeded;
	var travelSubject = reservation.travelSubject.uid;

	var paymentTypes = null;
	var isRoundTrip = false;

	var ancillaryFeesAvailable = ancillaryFeesAvailable || false;

	if (travelSubject === 'AIR') {
		var mandatoryObjectFields = ['servicesDetails','departureAirport','departureCity','arrivalAirport','arrivalCity','airline','operatingAirline','aircraft']

		_.each(reservation.legs, function (l) {
			_.each(l.segments, function (s) {
				for (var i = 0; i < mandatoryObjectFields; i++) {
					if (s[i] == null) s[i] = {};
				}
			});
		});
		var firstLeg = reservation.legs[0];
		var lastLeg = reservation.legs[_.size(reservation.legs) - 1];

		isRoundTrip = (
			reservation.legs.length === 2 &&
			firstLeg && lastLeg &&
			((_.first(firstLeg.segments).departureCity || {}).uid === (_.last(lastLeg.segments).arrivalCity || {}).uid) &&
			((_.last(firstLeg.segments).arrivalCity || {}).uid === (_.first(lastLeg.segments).departureCity || {}).uid)
		);

		if (firstLeg) {
			firstSegment = firstLeg.segments[0];
			lastSegment = firstLeg.segments[_.size(firstLeg.segments) - 1];
		}

		var docoDocaInfo = reservation.docoDocaNeededInfo;
		var docoDocaNeeded = docoDocaInfo != null && (docoDocaInfo.isDocaNeeded || docoDocaInfo.isDocoNeeded);
		var passengersHaveVisa = _.some(reservation.services, function (el) {
			return _.some(el.travellers, function(traveller) {
				return (!_.isEmpty(traveller.docoInfos) || !_.isEmpty(traveller.docaInfos));
			});
		});

		isVisaNeeded = docoDocaNeeded || passengersHaveVisa;
	} else if (travelSubject === 'RAILWAY') {
		var firstCarriage = reservation.carriages[0];
		var lastCarriage = reservation.carriages[_.size(reservation.carriages) - 1];

		firstSegment = firstCarriage && firstCarriage.segment;
		lastSegment =  firstCarriage && firstCarriage.segment;

		limitDate = (reservation.timeLimitCity != null) ? limitDate + ' (' + reservation.timeLimitCity + ')' : limitDate;

		paymentTypes = _.uniq(_.reduce(reservation.services, function(result, service) {
			if (service.paymentType != null) {
				result.push(service.paymentType.uid);
			}

			return result;
		}, []));
	}

	var hasChangeERegAvailable = _.some(reservation.services, function (el) {
		return (el.changeERegAvailable === true);
	});

	var hasRefundableService = _.some(reservation.services, function (el) {
		return (el.refundable === true);
	});

    var hasExchangeableService = _.some(reservation.services, function (el) {
        return (el.exchangeable === true);
    });

	var hasCompletedFlight = _.some(reservation.services, function (el) {
		return (el.certificateOfCompletedFlightCreationPossible === true);
	});

	var hasFlightReceipts = _.some(reservation.services, function (el) {
		return ((el._receipts && el._receipts.length) > 0);
	});

    function isBlurred(service) {
        return service.aclViewGranted ? '' : 'content-blur';
    }

	var isEditGrantedForAllServices = _.every(reservation.services, (p) => p.aclEditGranted);
	var hasEditingSerivce = hasChangeERegAvailable || hasRefundableService || hasExchangeableService;
	var showFooterOrderInfo =
        (reservation.travelPolicyStatus && reservation.travelPolicyStatus.uid === "AUTHORIZATION_REQUESTED") ||
		reservation.isCancelBookingAllowed ||
		reservation.isVoidBookingAllowed ||
        reservation.isRefundBookingAllowed ||
        isVisaNeeded ||
		['INTENTION', 'REFUND', 'BOOKING', 'PAYED', 'ISSUED'].indexOf(reservation.status.uid) !== -1 ||
		order.financeDocumentsCreated;
;
__p += '\n\n<div>\n	';
 if (travelSubject === 'AIR') { ;
__p += '\n        <div class="b-order__container-header">\n            <div class="b-order__container-header-label b-order-label">\n                <i class="g-icon-service g-icon-service--type-' +
((__t = ( travelSubject.toLowerCase() )) == null ? '' : __t) +
' ' +
((__t = ( ancillaryFeesAvailable ? 'mobile-hide' : '' )) == null ? '' : __t) +
' desktop-hide"></i>\n                <i class="g-icon-service g-icon-service--type-' +
((__t = ( travelSubject.toLowerCase() )) == null ? '' : __t) +
' mobile-hide "></i>\n                ';
 if (reservation.status.uid === 'ERROR') { ;
__p += '\n                    <span class="b-order__info-error" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-car-place-tooltip\'><div class=\'tooltip__header\'>' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</div><div class=\'tooltip__body\'>' +
((__t = ( L10N.get('settings.errorTechSupport') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</span>\n                ';
 } ;
__p += '\n                <div class="b-order-label__list">\n                    ';
 if (firstSegment && lastSegment) { ;
__p += '\n                        ';
 if (reservation.legs.length >= 2 && !isRoundTrip) { ;
__p += '\n                            <span data-toggle="tooltip"\n                                  data-placement="bottom"\n                                  data-html="true"\n                                  data-original-title="<div>' +
((__t = ( _.map(reservation.legs, function(l) {
                                        return ((_.first(l.segments).departureCity || {}).caption || '--') + '-' + ((_.last(l.segments).arrivalCity || {}).caption || '--');
                                    }).join('<br />') )) == null ? '' : __t) +
'</div>"\n                                    class="b-order-label__item b-order-label__route">\n                                ';
 _.each(reservation.legs, function(l) { ;
__p += '\n                                    <span class="b-order__route-location">' +
((__t = ( (_.first(l.segments).departureCity || {}).caption || '--' )) == null ? '' : __t) +
'</span>-<span class="b-order__route-location">' +
((__t = ( (_.last(l.segments).arrivalCity || {}).caption || '--' )) == null ? '' : __t) +
'</span>;\n                                ';
 }) ;
__p += '\n                            </span>\n                        ';
 } else { ;
__p += '\n                            <span class="b-order-label__item b-order-label__route">\n							<span class="b-order__route-location">' +
((__t = ( !_.isEmpty(firstSegment.departureCity) ? firstSegment.departureCity.caption : "-" )) == null ? '' : __t) +
'</span>-<span class="b-order__route-location">' +
((__t = ( !_.isEmpty(lastSegment.arrivalCity) ? lastSegment.arrivalCity.caption : "-" )) == null ? '' : __t) +
'</span>\n						</span>\n                        ';
 } ;
__p += '\n                    ';
 } ;
__p += '\n\n                    <span class="b-order-label__item b-order-label__create-date">\n					    ' +
((__t = ( createdDate )) == null ? '' : __t) +
'\n				    </span>\n\n                    <span>\n                        ';
 if (ancillaryFeesAvailable) { ;
__p += '\n                        <button class="b-order-label__tab-button active js-switch-tab ' +
((__t = ( selectedTab === 'TICKETS' ? 'active' : '' )) == null ? '' : __t) +
'" data-reservation-id="' +
((__t = ( index )) == null ? '' : __t) +
'" data-tab="TICKETS">\n                            <span>' +
((__t = ( L10N.get('cabinet.orders.airTickets') )) == null ? '' : __t) +
'</span>\n                        </button>\n                        <button class="b-order-label__tab-button js-switch-tab ' +
((__t = ( selectedTab === 'ANC_FEES' ? 'active' : '' )) == null ? '' : __t) +
'" data-reservation-id="' +
((__t = ( index )) == null ? '' : __t) +
'" data-tab="ANC_FEES">\n                            <span>' +
((__t = ( L10N.get('cabinet.orders.ancillaryFees') )) == null ? '' : __t) +
'</span>\n                        </button>\n                        ';
 } ;
__p += '\n                    </span>\n                </div>\n                ';
 if (reservation.incomplete) { ;
__p += '\n                    <span class="missing-info-warning"\n                          data-toggle="tooltip"\n                          data-placement="bottom"\n                          data-html="true"\n                          data-original-title="<div>' +
((__t = ( L10N.get('cabinet.orders.incompleteReservationWarning') )) == null ? '' : __t) +
'</div>"\n                    ></span>\n                ';
 } ;
__p += '\n            </div>\n            <div class="b-order__container-header-right">\n                ';
 if (reservation.prn != null) { ;
__p += '\n                    <span class="b-order__prn-caption">\n						<span>' +
((__t = ( L10N.get('cabinet.orders.reservationPnr') )) == null ? '' : __t) +
':</span>\n                        ';
 if (_.isArray(reservation.prn)) { ;
__p += '\n                            <span data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-order__prn-tooltip\'>';
 _.each(reservation.prn, function (item) { ;
__p += '\n									<div class=\'b-order__prn-tooltip-item\'>\n										<div class=\'b-order__prn-tooltip-title\'>' +
((__t = ( _.escape(item.prn) )) == null ? '' : __t) +
'</div>\n										';
 if (item.segment.departureLocation && item.segment.arrivalLocation) { ;
__p += '\n											<div class=\'b-order__prn-tooltip-caption\'>\n												' +
((__t = ( _.escape(item.segment.departureLocation.caption) )) == null ? '' : __t) +
'-' +
((__t = ( _.escape(item.segment.arrivalLocation.caption) )) == null ? '' : __t) +
'\n											</div>\n										';
 } ;
__p += '\n									</div>\n								';
 });
__p += '</div>">' +
((__t = ( reservation.prn[0].prn )) == null ? '' : __t) +
'</span>\n                        ';
 } else { ;
__p += '\n                            ' +
((__t = ( reservation.prn )) == null ? '' : __t) +
'\n                        ';
 } ;
__p += '\n                    </span>\n                ';
 } ;
__p += '\n                <div class="b-order-price">\n				<span data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-ticket/b-ticket-pricing.ejs')({ price: reservation._price || reservation.price })) )) == null ? '' : __t) +
'">\n					' +
((__t = ( Formatter.formatMoney(reservation.price.total.amount, Formatter.getDecimal(reservation.price.total.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(reservation.price.total.currencyCode) )) == null ? '' : __t) +
'\n				</span>\n                </div>\n                ';
 if (!(reservation.status && reservation.status.uid === 'ERROR')) {;
__p += '\n                    ';
 if (['INTENTION', 'BOOKING', 'AUTHORIZATION','PROPOSAL'].indexOf(reservation.status.uid) !== -1) { ;
__p += '\n                    <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedReservation.' + index + '.selected"})\'></span>\n                    ';
 } ;
__p += '\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    ';
 } ;
__p += '\n    ';
 if (travelSubject === 'RAILWAY') { ;
__p += '\n        <div class="b-order__container-header">\n            <div class="b-order__container-header-label b-order-label">\n                <i class="g-icon-service g-icon-service--type-' +
((__t = ( travelSubject.toLowerCase() )) == null ? '' : __t) +
'"></i>\n                ';
 if (reservation.status.uid === 'ERROR') { ;
__p += '\n                    <span class="b-order__info-error" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-car-place-tooltip\'><div class=\'tooltip__header\'>' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</div><div class=\'tooltip__body\'>' +
((__t = ( L10N.get('settings.errorTechSupport') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</span>\n                ';
 } ;
__p += '\n                <div class="b-order-label__list">\n                    ';
 if (firstSegment && lastSegment) { ;
__p += '\n                        <span class="b-order-label__item b-order-label__route">\n						<span class="b-order__route-location">' +
((__t = ( !_.isEmpty(firstSegment.departureLocation) ? firstSegment.departureLocation.caption : "-" )) == null ? '' : __t) +
'</span>-<span class="b-order__route-location">' +
((__t = ( !_.isEmpty(lastSegment.arrivalLocation) ? lastSegment.arrivalLocation.caption : "-" )) == null ? '' : __t) +
'</span>\n					</span>\n                    ';
 } ;
__p += '\n\n                    <span class="b-order-label__item b-order-label__create-date">\n                        ' +
((__t = ( createdDate )) == null ? '' : __t) +
'\n                    </span>\n\n                    ';
 if (reservation.prn != null) { ;
__p += '\n                        <span class="b-order-label__item">\n						<span class="b-order-label__item-simple">' +
((__t = ( L10N.get('cabinet.orders.reservationPnr') )) == null ? '' : __t) +
':</span>\n                            ';
 if (_.isArray(reservation.prn)) { ;
__p += '\n                                <span data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-order__prn-tooltip\'>';
 _.each(reservation.prn, function (item) { ;
__p += '\n									<div class=\'b-order__prn-tooltip-item\'>\n										<div class=\'b-order__prn-tooltip-title\'>' +
((__t = ( _.escape(item.prn) )) == null ? '' : __t) +
'</div>\n										';
 if (item.segment.departureLocation && item.segment.arrivalLocation) { ;
__p += '\n											<div class=\'b-order__prn-tooltip-caption\'>\n												' +
((__t = ( _.escape(item.segment.departureLocation.caption) )) == null ? '' : __t) +
'-' +
((__t = ( _.escape(item.segment.arrivalLocation.caption) )) == null ? '' : __t) +
'\n											</div>\n										';
 } ;
__p += '\n									</div>\n								';
 });
__p += '</div>">' +
((__t = ( reservation.prn[0].prn )) == null ? '' : __t) +
'</span>\n                            ';
 } else { ;
__p += '\n                                ' +
((__t = ( reservation.prn )) == null ? '' : __t) +
'\n                            ';
 } ;
__p += '\n                        </span>\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n            <div class="b-order__container-header-right">\n                <div class="b-order-price">\n                    ';

                        var priceArray = [reservation._price || reservation.price];
						if (reservation.priceBack) priceArray.push(reservation.priceBack);
                    ;
__p += '\n				<span data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-ticket/b-ticket-pricing.ejs')({ price: priceArray, totalPrice: reservation.totalPrice })) )) == null ? '' : __t) +
'">\n					' +
((__t = ( Formatter.formatMoney(reservation.totalPrice.amount, Formatter.getDecimal(reservation.totalPrice.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(reservation.totalPrice.currencyCode) )) == null ? '' : __t) +
'\n				</span>\n                </div>\n                ';
 if (!(reservation.status && reservation.status.uid === 'ERROR')) {;
__p += '\n                    ';
 if (['INTENTION', 'BOOKING', 'AUTHORIZATION', 'PROPOSAL'].indexOf(reservation.status.uid) !== -1) { ;
__p += '\n                    <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedReservation.' + index + '.selected"})\'></span>\n                    ';
 } ;
__p += '\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    ';
 } ;
__p += '\n    <span class="js-copy-content copy-content">' +
((__t = ( L10N.get("hotels.cost") )) == null ? '' : __t) +
': <span data-copy-content-from=".b-order-price > span"></span></span>\n    ';
 if (reservation.servicesStatuses && (reservation.servicesStatuses.refund || reservation.servicesStatuses.exchange)) { ;
__p += '\n        <span class="js-refund-exchange-tooltip" style="display: none" data-toggle="tooltip" data-placement="bottom" data-html="true"\n              data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-ticket/b-ticket-refund-exchange.ejs')({ services: {refund: reservation.refund || {}, exchange: reservation.exchange || {}} })) )) == null ? '' : __t) +
'"></span>\n        <span class="js-copy-content copy-content"><span data-find-copy-in-tooltip data-copy-title-from=".js-refund-exchange-tooltip"></span></span>\n    ';
 } ;
__p += '\n    ';
 if (reservation.status.uid === 'BOOKING') { ;
__p += '\n    <span class="js-copy-content copy-content"><span data-copy-content-from=".limit-date"></span></span>\n    ';
 } ;
__p += '\n    <span class="js-copy-content copy-content"><span data-find-copy-in-tooltip data-copy-title-from=".b-order-price > span"></span></span>\n	<div class="b-order__container-content ' +
((__t = ( selectedTab === 'TICKETS' ? '' : 'dn' )) == null ? '' : __t) +
'" data-tab-content="TICKETS">\n		<table class="b-block-table">\n			<tr class="b-block-table__row b-block-table__row-header">\n				';
 if (isEditGrantedForAllServices && (hasEditingSerivce === true && travelSubject === 'RAILWAY')) { ;
__p += '\n					<th class="b-block-table__cell b-block-table__cell-type-checkbox mobile-hide">\n						<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.' + index + '.allServices"})\'></span>\n					</th>\n				';
 } else { ;
__p += '\n					';
 if (isEditGrantedForAllServices && (hasCompletedFlight === true && !hasFlightReceipts || reservation.isVoidBookingAllowed || hasRefundableService)) { ;
__p += '\n						<th class="b-block-table__cell b-block-table__cell-type-checkbox mobile-hide">\n							<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServicesAvia.' + index + '.allServices"})\'></span>\n						</th>\n					';
 } else { ;
__p += '\n						<th class="b-block-table__cell b-block-table__cell-empty"></th>\n					';
 } ;
__p += '\n\n\n				';
 } ;
__p += '\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.ticketNumber') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.hotelFullName') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.documentNumber') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.documentExpiration') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.documentBirthday') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell b-block-table__cell--width-auto">\n                    ' +
((__t = ( L10N.get('cabinet.orders.hotelStatus') )) == null ? '' : __t) +
'\n                </th>\n				<th class="b-block-table__cell b-block-table__cell-empty"></th>\n			</tr>\n            ';
 if (isEditGrantedForAllServices && hasEditingSerivce === true && travelSubject === 'RAILWAY') { ;
__p += '\n            <tr class="b-block-table__row desktop-hide">\n                <td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption desktop-hide">\n                    <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.' + index + '.allServices"})\'></span>\n                    <span>' +
((__t = ( L10N.get('cabinet.orders.selectAll') )) == null ? '' : __t) +
'</span>\n                </td>\n            </tr>\n            ';
 } else { ;
__p += '\n                ';
 if (isEditGrantedForAllServices && (hasCompletedFlight === true && !hasFlightReceipts || reservation.isVoidBookingAllowed || hasRefundableService)) { ;
__p += '\n                <tr class="b-block-table__row desktop-hide">\n                    <td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption desktop-hide">\n                        <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServicesAvia.' + index + '.allServices"})\'></span>\n                        <span>' +
((__t = ( L10N.get('cabinet.orders.selectAll') )) == null ? '' : __t) +
'</span>\n                    </td>\n                </tr>\n                ';
 } ;
__p += '\n\n\n            ';
 } ;
__p += '\n\n			';
 _.each(reservation.services, function(p, i) {
				var passengers = p.travellers;
				var refundable = (p.refundable === true);
				var mco = p._mco;
				var flightReceipt = p._receipts;
				var aclEditGranted = p.aclEditGranted;
            ;
__p += '\n				<tr class="b-block-table__row ' +
((__t = ( mco != null ? 'with-mco' : '')) == null ? '' : __t) +
'" data-service-uid="' +
((__t = ( p.uid )) == null ? '' : __t) +
'" data-service-status="' +
((__t = ( p.status && p.status.uid )) == null ? '' : __t) +
'" data-voucher="' +
((__t = ( p.voucherPrinted )) == null ? '' : __t) +
'">\n					';
 if (hasEditingSerivce === true && travelSubject === 'RAILWAY' && aclEditGranted) { ;
__p += '\n						<td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption ">\n							<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.' + index + '.' + p.uid + '.selected"})\'></span>\n                            <span class="desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.selectService') )) == null ? '' : __t) +
'</span>\n						</td>\n					';
 } else if (hasCompletedFlight === true && !hasFlightReceipts && aclEditGranted) { ;
__p += '\n\n						<td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption ">\n							';
 if (p.certificateOfCompletedFlightCreationPossible === true) { ;
__p += '\n								<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServicesAvia.' + index + '.' + p.uid + '.selected"})\'></span>\n                                <span class="desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.selectService') )) == null ? '' : __t) +
'</span>\n							';
 } ;
__p += '\n						</td>\n\n					';
 } else if (reservation.isVoidBookingAllowed || refundable && aclEditGranted) { ;
__p += '\n                        <td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption ">\n                            <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServicesAvia.' + index + '.' + p.uid + '.selected"})\'></span>\n                            <span class="desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.selectService') )) == null ? '' : __t) +
'</span>\n                        </td>\n                    ';
 } else { ;
__p += '\n						<td class="b-block-table__cell b-block-table__cell-empty"></td>\n					';
 } ;
__p += '\n					<td class="b-block-table__cell b-block-table__cell-ticket-number ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n                        <div class="b-block-table__cell-content-container">\n                            <span class="b-block-table__cell-header-mobile desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.ticketNumber') )) == null ? '' : __t) +
'</span>\n                            <span class="b-order__info-ticket-number">\n							' +
((__t = ( (p.ticketNumber != null) ? p.ticketNumber : '一' )) == null ? '' : __t) +
'\n                            <span class="js-copy-content copy-content">' +
((__t = ( i > 0 ? '\n' : '')) == null ? '' : __t) +
'' +
((__t = ( L10N.get('cabinet.orders.ticketNumber') )) == null ? '' : __t) +
': ' +
((__t = ( (p.ticketNumber != null) ? p.ticketNumber : '一' )) == null ? '' : __t) +
' (' +
((__t = ( p.status && p.status.caption )) == null ? '' : __t) +
')</span>\n						</span>\n                            <span class="b-order__service-tags">\n                                ';
 if (p.eRegisterApplied === true) { ;
__p += '\n                                    <span class="red-tag" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="<div class=\'tooltip-left\'>' +
((__t = ( L10N.get('trains.eRegister') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('trains.eRegisterShort') )) == null ? '' : __t) +
'</span>\n                                    <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.eRegisterShort') )) == null ? '' : __t) +
': ' +
((__t = ( L10N.get('trains.eRegister') )) == null ? '' : __t) +
'</span>\n                                ';
 } ;
__p += '\n                            </span>\n                            ';
 _.each(mco, function (el) { ;
__p += '\n                                <div class="b-order-mco">\n								<span class="b-order__info-ticket-number">\n									' +
((__t = ( el.ticketNumber )) == null ? '' : __t) +
'\n								</span>\n                                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.eRegisterShort') )) == null ? '' : __t) +
': ' +
((__t = ( L10N.get('trains.eRegister') )) == null ? '' : __t) +
'</span>\n                                </div>\n                            ';
 }); ;
__p += '\n                            <!-- Flight receipts ticket number -->\n                            ';
 _.each(flightReceipt, function (el) { ;
__p += '\n                                <div class="b-order-mco">\n								<span class="b-order__info-ticket-number">\n									' +
((__t = ( el.ticketNumber )) == null ? '' : __t) +
'\n								</span>\n                                </div>\n                            ';
 }); ;
__p += '\n                        </div>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-fullname ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n                        ';
 let copyContent = [] ;
__p += '\n						';
 _.each(passengers, function (passenger) {
                            copyContent.push(passenger.fullName || '-');
                        ;
__p += '\n							<div>' +
((__t = ( passenger.fullName || '-' )) == null ? '' : __t) +
'</div>\n\n                            ';
 if (passenger.passportInfo != null) { ;
__p += '\n                                <div class="b-block-table__cell-content-container desktop-hide">\n                                    <span class="b-block-table__cell-header-mobile"> ' +
((__t = ( L10N.get('bookingForm.passportNumber') )) == null ? '' : __t) +
'</span>\n                                    <span data-toggle="tooltip" data-placement="bottom"\n                                    ';
 if (passenger.passportInfo.type != null) { ;
__p += '\n                                          title="' +
((__t = ( passenger.passportInfo.type.caption )) == null ? '' : __t) +
'"\n                                            ';
 } ;
__p += '>\n										' +
((__t = ( passenger.passportInfo.number )) == null ? '' : __t) +
'\n									</span>\n                                    ';
 if (passenger.passportInfo.type != null && passenger.passportInfo.type.uid !== "INTERNAL") { ;
__p += '\n                                        <span class="b-block-table__cell-header-mobile"> ' +
((__t = ( L10N.get('Common.until') )) == null ? '' : __t) +
'</span>\n                                        <span class="desktop-hide">\n                                            ' +
((__t = ( new Time(passenger.passportInfo.expiredDate).format('DD-MM-YYYY') )) == null ? '' : __t) +
'\n                                        </span>\n                                    ';
 } ;
__p += '\n                                </div>\n                            ';
 } ;
__p += '\n						';
 }); ;
__p += '\n						';
 _.each(mco, function (el) { ;
__p += '\n							<div class="b-order-mco">' +
((__t = ( L10N.get('trains.edmNa') )) == null ? '' : __t) +
' ' +
((__t = ( el.category && el.category.caption )) == null ? '' : __t) +
'</div>\n						';
 }); ;
__p += '\n						<!-- Flight receipts note -->\n						';
 _.each(flightReceipt, function (el) { ;
__p += '\n							<div class="b-order-mco">' +
((__t = ( el.category && el.category.caption )) == null ? '' : __t) +
'</div>\n						';
 }); ;
__p += '\n                        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.hotelFullName') )) == null ? '' : __t) +
': ' +
((__t = ( copyContent.join(', ') )) == null ? '' : __t) +
'</span>\n					</td>\n					<td class="b-block-table__cell mobile-hide ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n                        ' +
((__t = ( copyContent = [] )) == null ? '' : __t) +
'\n						';
 _.each(passengers, function (passenger) { ;
__p += '\n							';
 if (passenger.passportInfo != null) {
								let value = passenger.passportInfo.number;
								if (passenger.passportInfo.type != null) value += ' - ' + passenger.passportInfo.type.caption;
                                copyContent.push(value);
                            ;
__p += '\n								<div class="b-block-table__cell-content-container">\n                                    <span class="b-block-table__cell-header-mobile desktop-hide"> ' +
((__t = ( L10N.get('bookingForm.passportNumber') )) == null ? '' : __t) +
'</span>\n									<span data-toggle="tooltip" data-placement="bottom"\n										';
 if (passenger.passportInfo.type != null) { ;
__p += '\n											title="' +
((__t = ( passenger.passportInfo.type.caption )) == null ? '' : __t) +
'"\n										';
 } ;
__p += '>\n										' +
((__t = ( passenger.passportInfo.number )) == null ? '' : __t) +
'\n									</span>\n                                    ';
 if (passenger.passportInfo.type != null && passenger.passportInfo.type.uid !== "INTERNAL") { ;
__p += '\n                                        <span class="b-block-table__cell-header-mobile desktop-hide"> ' +
((__t = ( L10N.get('Common.until') )) == null ? '' : __t) +
'</span>\n                                        <span class="desktop-hide">\n                                            ' +
((__t = ( new Time(passenger.passportInfo.expiredDate).format('DD-MM-YYYY') )) == null ? '' : __t) +
'\n                                        </span>\n                                    ';
 } ;
__p += '\n								</div>\n							';
 } ;
__p += '\n						';
 }); ;
__p += '\n                        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('bookingForm.passportNumber') )) == null ? '' : __t) +
': ' +
((__t = ( copyContent.join(', ') )) == null ? '' : __t) +
'</span>\n					</td>\n					<td class="b-block-table__cell mobile-hide ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n                        ';
 copyContent = [];
__p += '\n						';
 _.each(passengers, function (passenger) { ;
__p += '\n							';
 if (passenger.passportInfo != null && passenger.passportInfo.type && passenger.passportInfo.type.uid !== "INTERNAL") {
							    const date = new Time(passenger.passportInfo.expiredDate).format('DD-MM-YYYY')
                                copyContent.push(date);
                            ;
__p += '\n								<div>' +
((__t = ( date )) == null ? '' : __t) +
'</div>\n							';
 } ;
__p += '\n						';
 }); ;
__p += '\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-birthdate mobile-hide ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n                        ';
 copyContent = [];
__p += '\n						';
 _.each(passengers, function (passenger) { ;
__p += '\n							';
 if (passenger.passportInfo != null) {
                                const date = new Time(passenger.passportInfo.birthday).format('DD-MM-YYYY')
                                copyContent.push(date);
                            ;
__p += '\n								<div class="b-block-table__cell-content-container">\n                                    <div class="b-block-table__cell-header-mobile desktop-hide">' +
((__t = ( L10N.get('bookingForm.birthDate') )) == null ? '' : __t) +
'</div>\n                                    <div>' +
((__t = ( date )) == null ? '' : __t) +
'</div>\n                                </div>\n							';
 } ;
__p += '\n						';
 }); ;
__p += '\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-status">\n                        <div class="b-order__info-status-container">\n                            <span class="b-order__info-status b-order__info-status--' +
((__t = ( p.status && p.status.uid )) == null ? '' : __t) +
'">' +
((__t = ( p.status && p.status.caption )) == null ? '' : __t) +
'</span>\n                            ';
 _.each(mco, function (el) { ;
__p += '\n                                <div class="b-order-mco">\n                                    <span class="b-order__info-status b-order__info-status--' +
((__t = ( el.status.uid )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( el.status && el.status.caption )) == null ? '' : __t) +
'\n                                    </span>\n                                </div>\n                            ';
 }); ;
__p += '\n                            ';
 _.each(flightReceipt, function (el) { ;
__p += '\n                                <div class="b-order-mco">\n                                    <span class="b-order__info-status b-order__info-status--' +
((__t = ( el.status.uid )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( el.status && el.status.caption )) == null ? '' : __t) +
'\n                                    </span>\n                                </div>\n                            ';
 }); ;
__p += '\n                            ';
 if (useAuthSteps) { ;
__p += '\n                                <button\n                                    type="button"\n                                    class="b-order__show-detailed js-open-auth-steps-modal"\n                                    data-reservation-uid="' +
((__t = ( reservation.uid )) == null ? '' : __t) +
'"\n                                    data-service-uid="' +
((__t = ( p.uid )) == null ? '' : __t) +
'"\n                                >\n                                    ' +
((__t = ( L10N.get('cabinet.orders.showDetails') )) == null ? '' : __t) +
'\n                                </button>\n                            ';
 } ;
__p += '\n                        </div>\n					</td>\n                    <td class="b-block-table__cell b-block-table__cell-travel-policy">\n                        <div class="b-block-table__cell-content-container order-reservation__travel-policy-container b-order-reservation__travel-policy">\n                            ';
 if (p.travelPolicyCompliance && !_.isEmpty(p.travelPolicyCompliance.rulesApplied)) { ;
__p += '\n                              ' +
((__t = ( require('@/blocks/elements/b-hotel/b-hotel-order/b-hotel-info/b-travel-policy.ejs')({
                                travelPolicyCompliance: p.travelPolicyCompliance,
                              }) )) == null ? '' : __t) +
'\n                            ';
 } ;
__p += '\n                            ';
 if (p.isTripartiteDiscount) { ;
__p += '\n                                <span class="b-order-reservation__travel-policy--3D g-icon-features-3D-contract" data-toggle="tooltip" data-placement="bottom" data-title="' +
((__t = ( L10N.get('filters.tripartite'))) == null ? '' : __t) +
'"></span>\n                            ';
 } ;
__p += '\n                        </div>\n                    </td>\n				</tr>\n			';
 }); ;
__p += '\n\n			';
 if (showFooterOrderInfo === true) { ;
__p += '\n				<tr class="b-block-table__row b-order__info-footer">\n					<td class="b-block-table__cell ' +
((__t = ( (hasEditingSerivce === true) ? 'b-block-table__cell-type-checkbox' : 'b-block-table__cell-empty' )) == null ? '' : __t) +
'"></td>\n					<td colspan="3" class="b-block-table__cell mobile-hide">\n						<div class="b-order__info-footer-controls">\n							';
 if (reservation.isCancelBookingAllowed && STATE.getLoggedInUser() != null) { ;
__p += '\n								<span class="b-order__info-footer-button b-payment__cancel">\n									' +
((__t = ( ['INTENTION'].indexOf(reservation.status.uid) !== -1 ? L10N.get('cabinet.orders.cancel') : L10N.get('trains.bookingCancellation') )) == null ? '' : __t) +
'\n							</span>\n							';
 } ;
__p += '\n							';
 if (reservation.isVoidBookingAllowed && STATE.checkSiteType('B2B')) { ;
__p += '\n								<span class="b-order__info-footer-button b-payment__void">' +
((__t = ( L10N.get('trains.bookingVoid') )) == null ? '' : __t) +
'</span>\n							';
 } ;
__p += '\n\n							';
 if (hasEditingSerivce === true) { ;
__p += '\n								';
 if (reservation.isRefundBookingAllowed && hasRefundableService) { ;
__p += '\n									<span class="b-order__info-footer-button b-refund-services btn-disabled">' +
((__t = ( L10N.get('trains.bookingRefund') )) == null ? '' : __t) +
'</span>\n								';
 } ;
__p += '\n                                ';
 if (hasExchangeableService) { ;
__p += '\n                                    <span class="b-order__info-footer-button b-exchange-services btn-disabled">' +
((__t = ( L10N.get('avia.bookingExchange') )) == null ? '' : __t) +
'</span>\n                                ';
 } ;
__p += '\n								';
 if (hasChangeERegAvailable) { ;
__p += '\n									<span class="b-order__info-footer-button b-change-ereg-send btn-disabled">' +
((__t = ( L10N.get('trains.changeER') )) == null ? '' : __t) +
'</span>\n									<span class="b-order__info-footer-text b-change-ereg-print disabled">' +
((__t = ( L10N.get('trains.requestChangeER') )) == null ? '' : __t) +
'</span>\n								';
 } ;
__p += '\n							';
 } ;
__p += '\n\n							';

							// IBECORP-3413
							// Add a visible class if only one reservation with voucherPrinted: true
							var checkServices = _.some(reservation.services, function(service) {
								return service.voucherPrinted;
							});

							var showVoucherByDefault = reservation && reservation.services && reservation.services.length === 1 && checkServices
								? 'b-order__voucher-message--shown'
								: '';
							;
__p += '\n\n							<span class="b-order__voucher-message ' +
((__t = ( showVoucherByDefault )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('trains.voucherPrinted') )) == null ? '' : __t) +
' <br /> ' +
((__t = ( L10N.get('trains.erDisabled') )) == null ? '' : __t) +
' </span>\n\n							<!-- button to request flight receipt -->\n							';
 if (hasCompletedFlight === true && !hasFlightReceipts) { ;
__p += '\n								<span class="b-order__info-footer-button b-get-flight-receipt btn-disabled">' +
((__t = ( L10N.get('avia.flightReceipts') )) == null ? '' : __t) +
'</span>\n							';
 } ;
__p += '\n						</div>\n\n\n						';
 if (!_.isEmpty(paymentTypes)) { ;
__p += '\n							<div class="b-order__info-notifications">\n								<div class="b-notification b-notification--type-alert b-order-info__notification">\n									<strong>' +
((__t = ( L10N.get('bookingForm.attention') )) == null ? '' : __t) +
': </strong>\n									';
 if (paymentTypes.includes('INVOICE')) { ;
__p += '\n										' +
((__t = ( L10N.get('cabinet.orders.paymentOnly') )) == null ? '' : __t) +
' <span class="b-order__important">' +
((__t = ( L10N.get('cabinet.orders.paymentOnly_INVOICE') )) == null ? '' : __t) +
'</span>\n									';
 } else if (paymentTypes.includes('CASH')) { ;
__p += '\n										' +
((__t = ( L10N.get('cabinet.orders.paymentOnly') )) == null ? '' : __t) +
' <span class="b-order__important">' +
((__t = ( L10N.get('cabinet.orders.paymentOnly_CASH') )) == null ? '' : __t) +
'</span>\n									';
 } else { ;
__p += '\n										' +
((__t = ( L10N.get('cabinet.orders.paymentOnly') )) == null ? '' : __t) +
' <span class="b-order__important">' +
((__t = ( L10N.get('cabinet.orders.paymentOnly_CREDIT_CARD') )) == null ? '' : __t) +
'</span>\n									';
 } ;
__p += '\n								</div>\n							</div>\n						';
 } ;
__p += '\n\n						';
 if (isVisaNeeded) {
							var docoDocaPassed = _.some(reservation.services, function (s) {
								return _.some(s.travellers, function (traveller) {
									return _.isEmpty(traveller.docoInfos) && _.isEmpty(traveller.docaInfos);
								});
							});
							var withoutDoco = _.some(reservation.services, function (s) {
								return _.some(s.travellers, function (traveller) {
									return !_.isEmpty(traveller.docoInfos) && traveller.docoInfos[0].visa === false;
								});
							});
						;
__p += '\n							<div class="b-order__docodoca-status">\n								';
 if (docoDocaPassed) { ;
__p += '\n									<span class="b-order__docodoca-status-item b-order__docodoca-status-item--fail collapsed collapse--with-anchor" data-toggle="collapse" data-target=".toggle-order__docodoca-container-' +
((__t = ( index )) == null ? '' : __t) +
'">\n										<span>' +
((__t = ( L10N.get('cabinet.orders.visa.notFilled') )) == null ? '' : __t) +
'</span>\n									</span>\n								';
 } else { ;
__p += '\n									<span class="b-order__docodoca-status-item b-order__docodoca-status-item--ok">\n										' +
((__t = ( (withoutDoco) ? L10N.get('cabinet.orders.visa.withoutDoco') : L10N.get('cabinet.orders.visa.filled') )) == null ? '' : __t) +
'\n									</span>\n								';
 } ;
__p += '\n							</div>\n						';
 } ;
__p += '\n					</td>\n					<td colspan="3" class="b-block-table__cell b-order__info-footer-right b-order__info-footer-top">\n						';
 if (reservation.status.uid === 'BOOKING') { ;
__p += '\n							<span class="b-order__info-footer-item">\n                                <div>\n                                    <div class="limit-date">' +
((__t = ( L10N.get('cabinet.orders.payUntil') )) == null ? '' : __t) +
'<span class="b-order__info-limitdate">' +
((__t = ( limitDate )) == null ? '' : __t) +
'</span></div>\n                                    ';
 if (reservation.timeExpirationMinutes) { ;
__p += '\n                                        <div class="js-expiration-count-container">\n                                            ' +
((__t = ( L10N.get('cabinet.orders.timeLeft') )) == null ? '' : __t) +
'\n                                            <span class="b-order__info-limitdate js-expiration-count"></span>\n                                        </div>\n                                    ';
 } ;
__p += '\n                                </div>\n\n							</span>\n						';
 } else if (reservation.approveAuthorizationAllowed && reservation.refuseAuthorizationAllowed) { ;
__p += '\n                            <span class="mobile-hide">\n								<button type="button" class="btn btn-danger b-order__refuse_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.refuse') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                            <span class="mobile-hide">\n								<button type="button" class="btn btn-green b-order__approve_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.confirm') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                            \n                        ';
 } else if (['REFUND', 'PAYED', 'ISSUED'].indexOf(reservation.status.uid) !== -1) { ;
__p += '\n							<span class="b-order__info-footer-item">\n								';
 if (travelSubject === 'AIR') { ;
__p += '\n\n								<!-- flight receipt download link -->\n								';
 if (hasFlightReceipts) { ;
__p += '\n									<span class="flight-receipt-action-link-wrapper b-download-flight-receipt mobile-hide">\n										<span class="action-link">' +
((__t = ( L10N.get('cabinet.orders.flightReceipt') )) == null ? '' : __t) +
'</span>\n										<i class="g-icon-download"></i>\n									</span>\n								';
 } ;
__p += '\n									<span class="b-route-receipt-link mobile-hide">\n										<span class="action-link">' +
((__t = ( L10N.get('cabinet.orders.routeDocument') )) == null ? '' : __t) +
'</span>\n										<i class="g-icon-download"></i>\n									</span>\n								';
 } else if (travelSubject === 'RAILWAY') { ;
__p += '\n									';
 var hasRefundService = _.some(reservation.services, function (p) {
										return p.status.uid === 'REFUND';
									}) ;
__p += '\n									<span class="b-route-receipt-link mobile-hide">\n										<span class="action-link">\n											' +
((__t = ( hasRefundService ? L10N.get('cabinet.orders.eTicketKRC') : L10N.get('cabinet.orders.eTicket') )) == null ? '' : __t) +
'\n										</span>\n										<i class="g-icon-download"></i>\n									</span>\n								';
 } ;
__p += '\n						';
 } else if (['AIR', 'RAILWAY'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('approvalNeeded') && reservation.approvalNeeded) { ;
__p += '\n							<span class="action-link mobile-hide">\n								<button type="button" class="btn btn-dark b-order__intention-approval" data-type="' +
((__t = ( reservation.travelSubject.uid === 'RAILWAY' ? 'RAILWAY' : 'AVIA' )) == null ? '' : __t) +
'">\n									' +
((__t = ( L10N.get('cabinet.orders.doBooking') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n						';
 } ;
__p += '\n            ';
  if (['AIR', 'RAILWAY', 'HOTEL_RESERVATION'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('bookAuthorizationAllowed') && reservation.bookAuthorizationAllowed === true) { ;
__p += '\n            <span class="action-link mobile-hide">\n              <button type="button" class="btn btn-dark b-booking-authorization">\n                ' +
((__t = ( L10N.get('cabinet.orders.doBooking') )) == null ? '' : __t) +
'\n              </button>\n            </span>\n            ';
 } ;
__p += '\n                        ';
 if (travelSubject === 'RAILWAY' && reservation.status.uid === 'ISSUED') {
                            var refundExpireDates = reservation.services ? _.reduce(reservation.services, function (acc, s) {
                                if (s.refundExpireDate && !acc.includes(s.refundExpireDate)) {
                                    acc.push(s.refundExpireDate);
                                }
                                return acc;
                            }, []) : [];
                            if (refundExpireDates.length) {
                                var withRefundExpireDate;
                                if (refundExpireDates.length === 1) {
                                    withRefundExpireDate = refundExpireDates[0];
                                } else {
                                    var now = new Time(new Date());
                                    var nowStr = now.format('YYYY-MM-DDTHH:mm:ss');
                                    
                                    var theVeryPastDate;
                                    var nearestFutureDate;
                                    for (let i = 0; i < refundExpireDates.length; i++) {
                                        var d = refundExpireDates[i];
                                        var dDate = new Time(d); 
                                        var diff = dDate.moment.diff(new Time(now));
                                        if (diff > 0) {
                                            if (nearestFutureDate) {
                                                if (dDate.moment.diff(new Time(nearestFutureDate)) < 0) {
                                                  nearestFutureDate = d;
                                                }
                                            } else {
                                                nearestFutureDate = d;
                                            }
                                        } else if (diff < 0) {
                                            if (theVeryPastDate) {
                                                if (dDate.moment.diff(new Time(theVeryPastDate)) > 0) {
                                                    theVeryPastDate = d;
                                                }
                                            } else {
                                                theVeryPastDate = d;
                                            } 
                                        }
                                    }
                                    if (nearestFutureDate) {
                                        withRefundExpireDate = nearestFutureDate;
                                    } else if (theVeryPastDate) {
                                        withRefundExpireDate = theVeryPastDate;
                                    }
                                }
                                if (withRefundExpireDate !== undefined) {
                        ;
__p += '\n                            <span class="b-order__info-footer-item">\n                                <div>\n                                    <div class="limit-date--with-m-top">' +
((__t = ( L10N.get('cabinet.orders.refundAvailableUntil') )) == null ? '' : __t) +
': ' +
((__t = ( new Time(withRefundExpireDate).format('DD.MM.YYYY HH:mm') )) == null ? '' : __t) +
'</div>\n                                </div>\n                            </span>\n                        ';
 }}} ;
__p += '\n					</td>\n                    <td class="b-block-table__cell mobile-cell">\n                        ';
 if (reservation.isCancelBookingAllowed) { ;
__p += '\n                            <span class="b-order__info-footer-button b-payment__cancel">\n									' +
((__t = ( ['INTENTION'].indexOf(reservation.status.uid) !== -1 ? L10N.get('cabinet.orders.cancel') : L10N.get('trains.bookingCancellation') )) == null ? '' : __t) +
'\n							</span>\n                        ';
 } ;
__p += '\n                        ';
 if (reservation.approveAuthorizationAllowed && reservation.refuseAuthorizationAllowed) { ;
__p += ' \n                            <span class="b-order__info-footer-button">\n								<button type="button" class="btn btn-danger b-order__refuse_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.refuse') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                            <span class="b-order__info-footer-button">\n								<button type="button" class="btn btn-green b-order__approve_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.confirm') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                        ';
 } ;
__p += '\n                        ';
 if (['AIR', 'RAILWAY'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('approvalNeeded') && reservation.approvalNeeded) { ;
__p += '\n                            <span class="b-order__intention-approval-container">\n                                <button type="button" class="btn btn-dark b-order__intention-approval" data-type="' +
((__t = ( reservation.travelSubject.uid === 'RAILWAY' ? 'RAILWAY' : 'AVIA' )) == null ? '' : __t) +
'">\n                                	' +
((__t = ( L10N.get('cabinet.orders.doBooking') )) == null ? '' : __t) +
'\n                                </button>\n							</span>\n                        ';
 } ;
__p += '\n                        ';
  if (['AIR', 'RAILWAY', 'HOTEL_RESERVATION'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('bookAuthorizationAllowed') && reservation.bookAuthorizationAllowed === true) { ;
__p += '\n                            <span class="b-order__intention-approval-container">\n                                <button type="button" class="btn btn-dark b-booking-authorization">\n                                	' +
((__t = ( L10N.get('cabinet.orders.doBooking') )) == null ? '' : __t) +
'\n                                </button>\n							</span>\n                        ';
 } ;
__p += '\n                        <div class="b-order__info-footer-controls-mobile js-show-mobile-controls desktop-hide expand-only"></div>\n                        <div class="b-order__info-footer-controls-popup js-mobile-controls desktop-hide">\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-download-flight-receipt">\n                                ' +
((__t = ( L10N.get('cabinet.orders.flightReceipt') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-route-receipt-link">\n                                ' +
((__t = ( travelSubject === 'AIR' ? L10N.get('cabinet.orders.routeDocument')
                                        : hasRefundService ? L10N.get('cabinet.orders.eTicketKRC') : L10N.get('cabinet.orders.eTicket') )) == null ? '' : __t) +
'\n                            </div>\n                            ';
 if (travelSubject === 'AIR') { ;
__p += '\n                                <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-get-flight-receipt">\n                                    ' +
((__t = ( L10N.get('avia.flightReceipts') )) == null ? '' : __t) +
'\n                                </div>\n                            ';
 } ;
__p += '\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-refund-services">\n                                ' +
((__t = ( L10N.get('trains.bookingRefund') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-change-ereg-send">\n                                ' +
((__t = ( L10N.get('trains.changeER') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-change-ereg-print">\n                                ' +
((__t = ( L10N.get('trains.requestChangeER') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-payment__void">\n                                ' +
((__t = ( L10N.get('trains.bookingVoid') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-expand">\n                                ' +
((__t = ( L10N.get('loginForm.rulesMore') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </td>\n					<td class="b-block-table__cell b-block-table__cell-empty"></td>\n				</tr>\n			';
 } else { ;
__p += '\n                <tr class="b-block-table__row b-order__info-footer">\n                    <td class="b-block-table__cell mobile-cell">\n                        <div class="b-order__info-footer-controls-mobile js-show-mobile-controls desktop-hide expand-only"></div>\n                        <div class="b-order__info-footer-controls-popup js-mobile-controls desktop-hide">\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-download-flight-receipt">\n                                ' +
((__t = ( L10N.get('cabinet.orders.flightReceipt') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-route-receipt-link">\n                                ' +
((__t = ( travelSubject === 'AIR' ? L10N.get('cabinet.orders.routeDocument')
                                        : hasRefundService ? L10N.get('cabinet.orders.eTicketKRC') : L10N.get('cabinet.orders.eTicket') )) == null ? '' : __t) +
'\n                            </div>\n                            ';
 if (travelSubject === 'AIR') { ;
__p += '\n                                <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-get-flight-receipt">\n                                    ' +
((__t = ( L10N.get('avia.flightReceipts') )) == null ? '' : __t) +
'\n                                </div>\n                            ';
 } ;
__p += '\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-refund-services">\n                                ' +
((__t = ( L10N.get('trains.bookingRefund') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-change-ereg-send">\n                                ' +
((__t = ( L10N.get('trains.changeER') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-change-ereg-print">\n                                ' +
((__t = ( L10N.get('trains.requestChangeER') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class=" b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn disabled" data-target-class=".b-payment__void">\n                                ' +
((__t = ( L10N.get('trains.bookingVoid') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-expand">\n                                ' +
((__t = ( L10N.get('loginForm.rulesMore') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </td>\n                </tr>\n            ';
 } ;
__p += '\n		</table>\n\n		<div class="b-order__info-expand b-expand collapsed"></div>\n	</div>\n    <div class="b-order__container-content ' +
((__t = ( selectedTab === 'ANC_FEES' ? '' : 'dn' )) == null ? '' : __t) +
' ' +
((__t = ( _.isEmpty(reservation.mcoServices) ? 'empty' : '' )) == null ? '' : __t) +
'" data-tab-content="ANC_FEES">\n        ';
 if (!_.isEmpty(reservation.mcoServices)) { ;
__p += '\n        <table class="b-block-table">\n            <tr class="b-block-table__row b-block-table__row-header">\n                <th class="b-block-table__cell b-block-table__cell-type-checkbox js-checkbox-cell">\n                    ';
 if (_.some(reservation.mcoServices, function(s) { return s.status && s.status.uid !== 'VOID_BOOKING' })) { ;
__p += '\n                    <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedMcoServicesAvia.' + index + '.allServices"})\'></span>\n                    ';
 } ;
__p += '\n                </th>\n                <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.receiptNumber') )) == null ? '' : __t) +
'</th>\n                <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.hotelFullName') )) == null ? '' : __t) +
'</th>\n                <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.serviceName') )) == null ? '' : __t) +
'</th>\n                <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.segment') )) == null ? '' : __t) +
'</th>\n                <th class="b-block-table__cell">\n                    ' +
((__t = ( L10N.get('cabinet.products.status') )) == null ? '' : __t) +
'\n                </th>\n                <th class="b-block-table__cell b-block-table__cell--width-auto">' +
((__t = ( L10N.get('cabinet.orders.sum') )) == null ? '' : __t) +
'</th>\n                <th class="b-block-table__cell b-block-table__cell-empty"></th>\n            </tr>\n            ';
 _.each(reservation.mcoServices, function (service) {
                var aclEditGranted = service.aclEditGranted;
            ;
__p += '\n				<tr class="b-block-table__row" data-mco-uid="' +
((__t = ( service.uid )) == null ? '' : __t) +
'">\n                    <td class="b-block-table__cell b-block-table__cell-type-checkbox">\n                        ';
 if (service.status && service.status.uid !== 'VOID_BOOKING' && aclEditGranted) { ;
__p += '\n                        <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedMcoServicesAvia.' + index + '.'+ service.uid +'.selected"})\'></span>\n                        ';
 } ;
__p += '\n                    </td>\n                    <td class="b-block-table__cell ' +
((__t = ( isBlurred(service) )) == null ? '' : __t) +
'">' +
((__t = ( service.mcoNumber || '----' )) == null ? '' : __t) +
'</td>\n                    <td class="b-block-table__cell ' +
((__t = ( isBlurred(service) )) == null ? '' : __t) +
'">\n                        ';
 _.each(service.travellers, function (t) { ;
__p += '\n							<div>' +
((__t = ( t.fullName )) == null ? '' : __t) +
'</div>\n                        ';
 }) ;
__p += '\n                    </td>\n                    <td class="b-block-table__cell ' +
((__t = ( isBlurred(service) )) == null ? '' : __t) +
'">' +
((__t = ( service.mcoCategory && service.mcoCategory.caption || '----' )) == null ? '' : __t) +
'</td>\n                    <td class="b-block-table__cell ' +
((__t = ( isBlurred(service) )) == null ? '' : __t) +
'">\n                        ';
 _.each(service.segments, function (item) {
                        var serviceSegment = reservation.legs[item.legIndex].segments[item.segmentIndex];
						var segmentName =  serviceSegment.departureCity != null && serviceSegment.arrivalCity != null ? serviceSegment.departureCity.caption + '-' + serviceSegment.arrivalCity.caption : '';
						;
__p += '\n                        <div>' +
((__t = ( segmentName )) == null ? '' : __t) +
'</div>\n                        ';
 }) ;
__p += '\n                    </td>\n                    <td class="b-block-table__cell">' +
((__t = ( service.status && service.status.caption || '----' )) == null ? '' : __t) +
'</td>\n                    <td class="b-block-table__cell ' +
((__t = ( isBlurred(service) )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.formatMoney(service.price.amount, Formatter.getDecimal(service.price.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(service.price.currencyCode) )) == null ? '' : __t) +
'</td>\n                </tr>\n            ';
 }) ;
__p += '\n            <tr class="b-block-table__row">\n                <td class="b-block-table__cell" colspan="4">\n                    <div class="b-order__ancillary-fees-buttons">\n                        <button class="b-order__reservation-remove-ancillary-fees js-cancelAncillaryFees disabled" data-reservation-uid="' +
((__t = ( reservation.uid )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('cabinet.orders.cancelService') )) == null ? '' : __t) +
'</button>\n                    </div>\n                </td>\n                <td class="b-block-table__cell" colspan="4">\n                    <div class="b-order__ancillary-fees-buttons">\n                        <button class="b-order__reservation-add-ancillary-fees js-getAncillaryFees" data-reservation-uid="' +
((__t = ( reservation.uid )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('cabinet.orders.addAdditionalServices') )) == null ? '' : __t) +
'</button>\n                    </div>\n                </td>\n            </tr>\n        </table>\n        ';
 } else { ;
__p += '\n            <div class="b-order__none-ancillary-fees-text js-empty-anc-fees-message">' +
((__t = ( L10N.get('avia.noServicesSelected') )) == null ? '' : __t) +
'</div>\n            <div class="b-order__ancillary-fees-buttons">\n                <button class="b-order__reservation-add-ancillary-fees js-getAncillaryFees" data-reservation-uid="' +
((__t = ( reservation.uid )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('cabinet.orders.addAdditionalServices') )) == null ? '' : __t) +
'</button>\n            </div>\n        ';
 } ;
__p += '\n\n    </div>\n	<div class="b-order__container-expand">\n		<div class="b-order__container-expand-inner"></div>\n		<div class="b-order__info-expand b-expand"></div>\n	</div>\n	<div class="toggle-order__docodoca-container toggle-order__docodoca-container-' +
((__t = ( index )) == null ? '' : __t) +
' collapse">\n		<div class="b-order__docodoca-container"></div>\n	</div>\n</div>\n';

}
return __p
}